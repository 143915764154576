//
// TODO: comments
//
export const SelectField = ({
  name,
  label,
  options = [],
  className = 'py-2',
  children,
  ...props
}) => {
  const {getField, setField, errors, isBusy} = useFormContext()

  return pug`
    div(className=className)
      if label !== false
        label= label

      Select(
        value=getField(name)
        onChange=(e)=>setField(name, e.target.value)
        invalid=errors[name]
        disabled=isBusy
        ...props
      )
        = children
        for option, i in options
          - let [key, value] = option
          option(key=(i + '-' + key) value=value)= value || key

      ErrorMessage(name=name)
  `
}
