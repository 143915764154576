import {SWRConfig} from 'swr'

//
// HTTP Caching layer.
//
export const SwrProvider = ({children}) => {
  const config = {
    suspense: true,
    interval: 30000,
  }
  return pug`SWRConfig(config=config children=children)`
}
