const route = {
  title: 'Locations',
  path: '/it/locations',
  scopes: ['it.manage'],
  menuGroup: 'Equipment',
}

export const ItLocationsPage = createPage(route, () => {
  const locations = useItLocations()

  const [id, setId] = React.useState(null)

  return pug`
    Main
      .p-1.xs_p-2.col.gap-2
        if id == 'new'
          LocationForm(id=id onClose=()=>setId(null))
        else
          Button(variant='outline-blue' onClick=()=>setId('new'))
            Icon.h-4(icon='plus')
            | New Location

      .col.gap-2.p-1.xs_p-2
        for location, i in locations
          .row.items-center(key=location.id)
            .w-6.pr-2.text-right.font-black.opacity-20= i
            if id == location.id
              LocationForm(id=id onClose=()=>setId(null))
            else
              button.col.py-1.px-2.text-lg.bg-white.border.border-gray-500.shadow.rounded(
                onClick=()=>setId(location.id)
              )= location.name
  `
})

const LocationForm = ({id, onClose}) => {
  const createLocation = useItLocations.useCreate()
  const updateLocation = useItLocations.useUpdate()
  const deleteLocation = useItLocations.useDelete()

  const location = useItLocations.useOne({id})

  const onSubmit = (body) => (id == 'new' ? createLocation(body) : updateLocation(body))

  const onDelete = () =>
    confirm(`Deleting '${location.name}'. Are you sure?`) && deleteLocation({id})

  return pug`
    FormProvider(initialValues=location onSubmit=onSubmit onSuccess=onClose onCancel=onClose)
      TextField.flex-1(id='location-name' focus name='name' placeholder='Name')

      .row.items-center.justify-between.gap-2
        button.text-blue-600.underline(onClick=onClose) Cancel
        .flex-1
        if id != 'new'
          button.text-red-600.underline(onClick=onDelete) Delete
        SubmitButton(variant='blue') Save
  `
}
