//
// x = {a_attributes: {}, b_id: 1, c: 3}
//
// hasField(x, 'a') => 'a_attributes'
// hasField(x, 'b') => 'b_id'
// hasField(x, 'c') => 'c'
// hasField(x, 'd') => undefined
//
export const hasField = (obj, field) => {
  // eslint-disable-next-line no-prototype-builtins
  if (obj.hasOwnProperty(field)) return field
  // eslint-disable-next-line no-prototype-builtins
  if (obj.hasOwnProperty(`${field}_id`)) return `${field}_id`
  // eslint-disable-next-line no-prototype-builtins
  if (obj.hasOwnProperty(`${field}_attributes`)) return `${field}_attributes`
}
