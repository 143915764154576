const route = {
  title: 'Users',
  path: '/admin/users',
  scopes: ['users.manage'],
  menuGroup: 'Admin',
}

export const AdminUsersPage = createPage(route, () => {
  const users = useUsers().sort((a, b) => a.name.localeCompare(b.name))

  return pug`
    Main
      unless users.length
        .min-h-full.p-2.col.center
          Icon.h-20.mb-4.text-gray-500.opacity-50(icon='users')
          p Looks like there aren't any users yet.

      else
        .col.gap-1.xs_gap-2.p-1.xs_p-2
          for user, i in users
            .row.items-center(key=user.id)
              .w-6.pr-2.text-right.font-black.opacity-20= i
              Link.col.py-1.px-2.text-lg.bg-white.border.border-gray-500.shadow.rounded(
                to=AdminUserFormPage.path({id: user.id})
              )
                .font-bold.text-gray-600= user.name
                .text-sm.text-gray-800= user.email
  `
})
