const route = {
  title: 'Issue',
  path: '/it/issues/:id',
  scopes: ['it.manage'],
  menuGroup: null,
}

export const ItIssueFormPage = createPage(route, () => {
  const params = useParams()
  const issue = useItIssues.useOne({id: params.id})

  return pug`
    Main
      unless issue
        .min-h-full.p-2.col.center
          Icon.h-20.mb-4.text-red-800.opacity-50(icon='exclamation-triangle')
          p Looks like this issue doesn't exist.

      else
        IssueHeader
        IssueComments
  `
})

const IssueHeader = () => {
  const navigate = useNavigate()
  const me = useMe()
  const hasAnyScopes = useHasAnyScopes()

  const params = useParams()
  const issue = useItIssues.useOne({id: params.id})
  const user = useUsers.useOne({id: issue.user_id})

  const [isEditing, setIsEditing] = React.useState(false)
  const open = () => setIsEditing(true)
  const close = () => setIsEditing(false)

  const updateIssue = useItIssues.useUpdate()
  const deleteIssue = useItIssues.useDelete()

  const onDelete = async () => {
    if (!confirm(`Deleting '${issue.title}'. Are you sure?`)) return

    await deleteIssue({id: issue.id})
    navigate(ItIssuesPage.path())
  }

  return pug`
    FormProvider(initialValues=issue onSubmit=updateIssue onSuccess=close onCancel=close)
      .col.py-4.px-1.xs_px-2
        // Go back link
        Link(to=ItIssuesPage.path())
          .mb-4.row.items-center.gap-2.text-lg.text-blue-800.underline
            Icon.h-4(icon='arrow-left')
            | All Issues

        // Context & Actions
        .row.items-start.gap-2
          Icon.inline.h-6.text-gray-500(icon='user-circle')
          .text-xl.leading-none.font-bold.text-gray-600= user.name

          if isEditing
            .flex-1
            button.text-yellow-800.underline(onClick=close) Cancel

          else if issue.user_id == me.id || hasAnyScopes('it.manage')
            .flex-1
            button.text-blue-800.underline(onClick=open) Edit

        // Form
        if isEditing
          TextField(name='title' label='Title')
          TextAreaField(rows=8 name='description' label='Description' placeholder='No description')

          .mb-4.row.items-start.justify-between
            button.text-red-600.underline(onClick=onDelete) Delete
            SubmitButton(variant='blue') Save

        // Title & Description
        else
          .py-4.text-4xl.font-semibold.text-gray-800= issue.title
          .whitespace-pre-wrap.text-lg= issue.description || 'No description'

          .mb-4.row.items-start.justify-between
            .h-0
            .text-sm.italic.text-gray-500= dayjs(issue.created_at).fromNow()

  `
}

const IssueComments = () => {
  const me = useMe()

  const params = useParams()
  const issue = useItIssues.useOne(params)

  const comments = useItComments({it_issue_id: params.id})

  const [editingId, setEditingId] = React.useState(null)

  return pug`
    .col.gap-4.py-4.px-1.xs_px-2.border-t.border-gray-500
      for comment in comments
        if editingId == comment.id
          CommentForm(key=comment.id id=editingId onClose=()=>setEditingId(null))
        else
          Comment(key=comment.id id=comment.id onEdit=()=>setEditingId(comment.id))

      if editingId == 'new'
        CommentForm(id=editingId onClose=()=>setEditingId(null))
      else
        Button(variant='outline-blue' onClick=()=>setEditingId('new')) New Comment
  `
}

const CommentContext = ({id, onEdit, onCancel}) => {
  const me = useMe()

  const params = useParams()
  const comment = useItComments.useOne({it_issue_id: params.id, id})
  const user = useUsers.useOne({
    id: id == 'new' ? me.id : comment.user_id,
  })

  return pug`
    .row.items-start.gap-1
      Icon.h-6.text-gray-500(icon='user-circle')
      .text-xl.leading-none.font-bold.text-gray-600= user.name

      if onEdit
        .flex-1
        button.text-blue-800.underline(onClick=onEdit) Edit

      if onCancel
        .flex-1
        button.text-yellow-800.underline(onClick=onCancel) Cancel
  `
}

const Comment = ({id, onEdit}) => {
  const params = useParams()
  const comment = useItComments.useOne({it_issue_id: params.id, id})

  return pug`
    .col.gap-1
      CommentContext(id=id onEdit=onEdit)
      .text-xl= comment.message

      .row.items-start.justify-between
        .h-0
        .px-2.text-sm.italic.text-gray-500= dayjs(comment.created_at || Date.now()).fromNow()
  `
}

const CommentForm = ({id, onClose}) => {
  const me = useMe()

  const params = useParams()
  const comment = useItComments.useOne({it_issue_id: params.id, id})
  const user = useUsers.useOne({id: comment.user_id})

  const initialValues = {
    it_issue_id: params.id,
    user_id: me.id,
    message: '',
    ...comment,
  }

  const createComment = useItComments.useCreate()
  const updateComment = useItComments.useUpdate()
  const deleteComment = useItComments.useDelete()

  const onSubmit = id == 'new' ? createComment : updateComment

  const onDelete = async () => {
    if (!confirm(`Deleting comment. Are you sure?`)) return

    await deleteComment({it_issue_id: params.id, id})
  }

  return pug`
    FormProvider(initialValues=initialValues onSubmit=onSubmit onSuccess=onClose onCancel=onClose)
      .col.gap-1
        CommentContext(id=id onCancel=onClose)

        TextAreaField(
          rows=3
          focus
          id='comment-message'
          name='message'
          placeholder='My detailed experience...'
        )

        .row.items-start.justify-between
          button.text-red-600.underline(onClick=onDelete) Delete
          SubmitButton(variant='blue') Save
  `
}
