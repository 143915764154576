export const SubmitButton = ({className, children, ...props}) => {
  const {isBusy, onSubmit} = useFormContext()

  return pug`
    Button(
      className=className
      disabled=isBusy
      onClick=onSubmit
      ...props
    )= children
  `
}
