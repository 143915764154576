export const Modal = ({title, className = ' ', children, ...props}) => {
  const {status} = useOverlay()
  const zIndex = status == 'closed' ? -1 : 100
  const bgClx = status == 'open' ? ' opacity-100 ' : ' opacity-0 '
  const cardClx = status == 'open' ? ' scale-100 opacity-100 ' : ' scale-50 opacity-0 '

  return pug`
    .fixed.inset-0.col.center.bg-black.bg-opacity-40.backdrop-blur-md.smooth(
      className=bgClx
      style={zIndex}
      onClick=Overlay.close
    )
      .bg-gray-100.border.border-gray-300.rounded.shadow-lg.smooth.overflow-y-scroll(
        className=(cardClx + className)
        onClick=(e)=>e.stopPropagation()
      )
        if title
          h1.mb-4.text-4xl.text-gray-800= title
        = children
  `
}
