//
// Creates a Provider & useContext hook for some app state.
//
// For example:
//
//   const {Provider: NoodlesProvider, useContext: useNoodles} =
//     createContext((initialNoodles = []) => {
//       const [noodles, setNoodles] = React.useState(initialNoodles)
//       return {noodles, setNoodles}
//     })
//

export const createContext = (provide) => {
  const Context = React.createContext({})
  const Consumer = Context.Consumer
  const useContext = () => React.useContext(Context)

  const Provider = (props) => pug`
    Context.Provider(value=provide(props))
      = props.children
  `

  return {Context, Provider, Consumer, useContext}
}
