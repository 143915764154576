//
// Focuses an input field when the component mounts.
//
export const useFocusOnMount = (id) =>
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      const el = document.getElementById(id)
      if (!el) return

      el.focus()
      el.selectionStart = el.selectionEnd = 10000
    }, 200)
    return () => clearTimeout(timeout)
  }, [])
