export const PAGES = [
  ActLessonsPage,
  ActTargetsPage,
  ActClassroomsPage,
  ActClassroomDetailsPage,
  ActClassroomFormPage,

  ItIssueFormPage,
  ItIssuesPage,

  ItIndexPage,
  ItKindsPage,
  ItLocationsPage,
  ItLogPage,

  ApExpensesPage,
  ApInvoicesPage,
  ApPayrollPage,

  ArAccountsPage,
  ArAccountFormPage,
  ArAddressFormPage,
  ArPartyFormPage,
  ArInvoiceGeneratorPage,
  ArReceiptGeneratorPage,
  ArGrantGeneratorPage,
  ArSummaryGeneratorPage,

  AdminUserFormPage,
  AdminUsersPage,

  MyAccountPage,
  MyExpensesPage,
  MyPayPage,
]
export const PAGE_GROUPS = groupBy(PAGES, (page) => page.menuGroup)
delete PAGE_GROUPS.null

const $goto = (path) => pug`Navigate(to=path)`

export const Routes = () => {
  const params = useParams()
  const {loading} = useAuthTokenEffect()

  return pug`
    if loading
      .flex-1.w-full: Loading
    else
      AuthenticatedRoutes
  `
}

const AuthenticatedRoutes = () => {
  const params = useParams()
  const me = useMe()
  const hasAnyScopes = useHasAnyScopes()

  const defaultPath = hasAnyScopes('act.teach', 'act.observe', 'act.manage')
    ? ActLessonsPage.path(params)
    : MyAccountPage.path(params)

  return pug`
    unless me.id
      .flex-1.w-full: Loading

    else
      ReactRouterDomRoutes
        for page in PAGES
          if hasAnyScopes(...page.scopes)
            Route(
              key=page.pathTemplate
              path=page.pathTemplate
              Component=page
            )

        Route(path='' element=$goto(defaultPath))
        Route(path='*' element=$goto(defaultPath))
  `
}
