const route = {
  title: 'Payroll',
  path: '/ap/payroll',
  scopes: ['ap.manage'],
  menuGroup: 'Payables',
}

export const ApPayrollPage = createPage(route, () => {
  return pug`
    Main
      .min-h-full.p-2.col.center
        Icon.h-20.mb-4.text-red-800.opacity-50(icon='times-circle')
  `
})
