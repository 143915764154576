//
// x = {a: 1, b_attributes: [{c: d}]}
//
// getIn(x, 'a') => 1
// getIn(x, 'b_attributes.0.c'.split('.')) => d
//
export const setIn = (obj, [field, ...fields], value) => {
  if (!field) return value

  if (Array.isArray(obj) && field?.match(/^\d+$/u)) {
    return Object.assign([], obj, {
      [field]: setIn(obj[field], fields, value),
    })
  }
  if (obj instanceof Object) {
    const match = hasNestedField(obj, field)
    return {
      ...obj,
      [match || field]: setIn(obj[match || field], fields, value),
    }
  }

  if (field?.match(/^\d+$/u)) return [setIn(null, fields, value)]
  return {[field]: setIn(null, fields, value)}
}
