const store = (key, val) => {
  if (typeof window === 'undefined') return null
  if (val) {
    window.localStorage.setItem(key, val)
  } else {
    window.localStorage.removeItem(key)
  }
}

const getStored = (key) => {
  if (typeof window === 'undefined') return null
  try {
    return window.localStorage.getItem(key)
  } catch (e) {
    console.error(e)
  }
}

const storeToken = (token) => store('erp.token', token)
const getStoredToken = () => getStored('erp.token')

//
// Used for OIDC auth
//
export const oidcManager = () =>
  import('oidc-client').then(({UserManager, Log}) => {
    Log.logger = console
    Log.level = Log.WARN

    return new UserManager({
      authority: import.meta.env.VITE_API_URL,
      client_id: import.meta.env.VITE_CLIENT_ID,

      response_type: 'id_token token',
      scope: 'openid read write',

      redirect_uri: `${import.meta.env.VITE_SPA_URL}/sign-in-redirect`,
      post_logout_redirect_uri: `${import.meta.env.VITE_SPA_URL}/sign-out-redirect`,

      automaticSilentRenew: true,
      filterProtocolClaims: true,
      loadUserInfo: false,
    })
  })

//
// AuthState
//
export const useAuth = zus(() => ({
  loading: true,
  token: null,
}))

//
// If `loading` is true, then as soon as the app is mounted,
//   we load an auth token from storage.
//
export const useAuthTokenEffect = () => {
  const location = useLocation()
  const {loading, token} = useAuth()

  useEffect(() => {
    if (!loading) return

    if (!token) {
      console.log('[auth-token-effect] Need an auth token to continue')

      // Step 0 (there's no token in the store, but we just redirected from sign in)
      //
      //  Note: this is called when the page at the oidc `redirect_uri` path is mounted.
      //
      //  If there's a new token in the response, save it (which will move us to step 2).
      //
      if (location.pathname === '/sign-in-redirect') {
        console.log('[auth-token-effect] Trying to get token from /sign-in-redirect query')
        const [_, newToken] = location.hash
          .split('&')
          .map((pair) => pair.split('='))
          .find(([key]) => key === 'access_token')

        if (!newToken) {
          console.log('[auth-token-effect] Token missing from /sign-in-redirect query!!!')
          // TODO
          throw new Error()
        }

        console.log('[auth-token-effect] Saving new token from /sign-in-redirect query')
        storeToken(newToken)
        useAuth.setState({token: newToken})
        return
      }

      // Step 1 (no token in the store)
      //
      //   Either get a token from localStorage or sign in (see step 0 above).
      //
      const storedToken = getStoredToken()
      if (storedToken) {
        console.log('[auth-token-effect] Continuing with stored token')
        useAuth.setState({token: storedToken})
      } else {
        console.log('[auth-token-effect] No token found in storage, redirecting to login')
        oidcManager().then((m) => m.signinRedirect({state: 'some data'}))
      }
      return
    }

    // Step 2 (we have a token, let's go back to wherever we need to be)
    //
    //   TODO: we could go the page the user asked for here
    //
    if (location.pathname === '/sign-in-redirect') {
      console.log('[auth-token-effect] Leaving /sign-in-redirect with token')
      window.history.replaceState({}, '', '/')
    }

    console.log('[auth-token-effect] Done')
    useAuth.setState({loading: false})
  }, [loading, token])

  return {loading, token}
}

// Step N (sign the user out whenever)
//
//   Before redirecting to the sign out path,
//     remove all api data, both from redux and localStorage.
//
export const signOut = () => {
  storeToken(null)
  useAuth.setState({token: null})
  window.localStorage.removeItem('erp.token')
  window.location = `${import.meta.env.VITE_API_URL}/users/sign_out`
}
