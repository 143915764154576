export const useArAccounts = createQuery('/ar_accounts')

useArAccounts.useCreate = createMutation('POST', '/ar_accounts', (ctx) => {
  useArAccounts.prependOne(ctx)
})
useArAccounts.useUpdate = createMutation('PATCH', '/ar_accounts/:id', (ctx) => {
  useArAccounts.replaceOne(ctx)
})
useArAccounts.useDelete = createMutation('DELETE', '/ar_accounts/:id', (ctx) => {
  useArAccounts.removeOne(ctx)
})
