//
// Creates an callback for making authenticated HTTP requests to the backend.
//
// For example:
//
//   const apiFetch = useApiFetch()
//
//   ... later somewhere in the same component
//
//   const secretIdentity = apiFetch('/users/me', {
//     method: 'PATCH',
//     body: {name: 'Batman!'},
//   })
//
export const useApiFetch = () => {
  const {token} = useAuth()

  return async (path, options = {}) => {
    try {
      const rep = await fetch(`${import.meta.env.VITE_API_URL}/api${path}`, {
        ...options,
        headers: {
          ...(options.headers || {}),
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: options.method == 'GET' ? null : JSON.stringify(options.body || {}),
      })
      if (rep.status == 401) signOut()
      return await rep.json()
    } catch (e) {
      console.error(e)
      return {data: {}, errors: {base: e}, meta: {}}
    }
  }
}
