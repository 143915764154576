const basePath = '/ar_accounts/:ar_account_id/ar_parties'

export const useArAccountParties = createQuery(basePath)

useArAccountParties.useCreate = createMutation('POST', basePath, (ctx) => {
  useArAccountParties.appendOne(ctx)
  useArParties.appendOne(ctx)
})
useArAccountParties.useUpdate = createMutation('PATCH', `${basePath}/:id`, (ctx) => {
  useArAccountParties.replaceOne(ctx)
  useArParties.replaceOne(ctx)
})
useArAccountParties.useDelete = createMutation('DELETE', `${basePath}/:id`, (ctx) => {
  useArAccountParties.removeOne(ctx)
  useArParties.removeOne(ctx)
})
