export const useArParties = createQuery('/ar_parties')

useArParties.useCreate = createMutation('POST', '/ar_parties', (ctx) => {
  useArAccountParties.appendOne(ctx)
  useArParties.appendOne(ctx)
})
useArParties.useUpdate = createMutation('PATCH', '/ar_parties/:id', (ctx) => {
  useArAccountParties.replaceOne(ctx)
  useArParties.replaceOne(ctx)
})
useArParties.useDelete = createMutation('DELETE', '/ar_parties/:id', (ctx) => {
  useArAccountParties.removeOne(ctx)
  useArParties.removeOne(ctx)
})
