//
// TODO: comments
//
export const FormSection = ({title, number = 0, children}) => pug`
  h3.text-lg.flex.items-center
    .w-7.h-7.flex.items-center.justify-center.text-sm.rounded-full.bg-gray-500.text-white= number
    .flex-1.pl-3= title
  .pl-3.pb-3
    .pl-4.border-l-2.border-gray-500= children
`
