//
// Separates errors between fields we're using and everything else
//   also renames '[]' indexes to '.' indexes ('a[0].b' becomes 'a.0.b')
//   also adds '_attributes' to api errors if that's what we're using
//
// form = {a: 1, b: 2, c_attributes: [{d: e}]}
// api = {'a': [x], 'c[0].d': [y], 'f': [z]}
//
// validationAdapter(form, api)
// => [
//      {'a': [x], 'c_attributes.0.d': [y]},
//      {'f': [z]},
// ]
//
export const validationAdapter = (formValues = {}, apiErrors = {}) => {
  const matched = {}
  const missing = {}

  Object.keys(apiErrors).forEach((name) => {
    const splitNames = name.split(/[[\].]+/u)
    const match = hasNestedField(formValues, ...splitNames)
    if (match) {
      matched[match] = apiErrors[name]
    } else {
      missing[splitNames.join('.')] = apiErrors[name]
    }
  })
  console.log({matched, missing})

  return [matched, missing]
}
