const BASE =
  ' font-normal border bg-gray-50 focus_bg-white ' +
  ' focus_ring-2 ring-primary-600 ' +
  ' outline-1 outline-offset-1 outline-primary-600 ' +
  ' shadow-sm focus_shadow-md smooth '

const ENABLED_CLX = ' border-gray-400 text-gray-600 focus_text-gray-700 '
const INVALID_CLX = ' border-red-600 text-gray-600 focus_text-gray-700 '
const DISABLED_CLX = ' border-gray-800 text-gray-400 '

const SIZE = {
  sm: ' px-2 py-1 text-sm rounded-md ',
  md: ' px-2 py-1 text-base rounded-md ',
  lg: ' px-2 py-1 text-lg rounded-lg ',
}

export const Input = React.forwardRef(
  (
    {size = 'md', invalid, disabled, className = ' ', focus = false, ...props},
    ref,
  ) => {
    className += BASE + (SIZE[size] || SIZE.md)
    if (invalid) {
      className += INVALID_CLX
    } else if (disabled) {
      className += DISABLED_CLX
    } else {
      className += ENABLED_CLX
    }

    useFocusOnMount(focus ? props.id : null)

    return pug`
      input.block.w-full.h-10(
        className=className
        disabled=disabled
        invalid=invalid ? 1 : 0
        ref=ref
        ...props
      )
    `
  },
)
