import dayjs from 'dayjs'

import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)
dayjs.tz.setDefault('UTC')

import weekday from 'dayjs/plugin/weekday'
dayjs.extend(weekday)

import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export {dayjs}
