//
// Creates a new page with routing and authz information.
//
export const createPage = (
  {icon, title, path: pathTemplate, scopes = [], menuGroup} = {},
  PageComponent,
) => {
  // Assign a new name to the PageComponent, which appears in React DevTools' Components tree.
  Object.defineProperty(PageComponent, 'name', {value: `PageComponent(${pathTemplate})`})

  const path = createPath(pathTemplate)

  const Page = () => pug`
    Helmet: title #{title} - TechLit Records
    NavHeader(title=title path=path)
    Suspend: PageComponent
  `

  Page.icon = icon
  Page.title = title
  Page.pathTemplate = pathTemplate
  Page.path = path
  Page.scopes = scopes
  Page.menuGroup = menuGroup

  return Page
}
