const route = {
  title: 'Classroom Details',
  path: '/act/classrooms/:id/details',
  scopes: ['act.manage'],
  menuGroup: null,
}

export const ActClassroomDetailsPage = createPage(route, () => {
  const naviate = useNavigate()
  const params = useParams()

  const createGroup = useActGroups.useCreate()
  const updateGroup = useActGroups.useUpdate()
  const deleteGroup = useActGroups.useDelete()

  const classroom = useActClassrooms.useOne({id: params.id})
  const groupsById = useActGroups.useById()
  const groups = React.useMemo(() => {
    return (classroom.act_group_ids || [])
      .map((id) => groupsById[id])
      .filter(Boolean)
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [classroom.act_group_ids, groupsById])

  const [editingId, setEditingId] = React.useState(null)
  const onStopEditing = () => setEditingId(null)

  const newGroup = {act_classroom_id: params.id, name: '', attend_max: 0, lessons_min: 0, lessons_max: 0}

  return pug`
    Main
      .col.gap-y-2.p-1.xs_p-2
        Link.row.items-center.gap-2.text-lg.text-blue-800.underline(
          to=ActClassroomsPage.path()
        )
          Icon.h-4(icon='arrow-left')
          | All Classrooms

        .row.items-end.gap-2.my-6
          .text-4xl.font-black.text-gray-800= classroom.name

          Link.row.items-center.gap-2.text-lg.text-blue-800.underline(
            to=ActClassroomFormPage.path({id: params.id})
          ) (edit)

        unless editingId
          .flex-1
          Button(variant='blue' onClick=()=>setEditingId('new'))
            Icon.h-4(icon='plus')
            | New Group

        .grid.grid-cols-5.items-center.gap-2
          .col-span-2 Group
          .row.justify-end.pr-1: Icon.h-4(icon='users')
          .row.justify-end.pr-1: Icon.h-4(icon='bullseye')
          .row.justify-end.pr-2: Icon.h-4(icon='star')

        if editingId == 'new'
          GroupForm(id='new' onClose=onStopEditing)

        for group in groups
          if editingId == group.id
            GroupForm(key=group.id id=group.id onClose=onStopEditing)
          else
            button.grid.grid-cols-5.py-1.px-2.text-lg.bg-white.border.border-gray-500.shadow.rounded(key=group.id onClick=()=>setEditingId(group.id))
              .row.items-center.col-span-2= group.name
              .row.justify-end= group.attend_max
              .row.justify-end= group.lessons_min
              .row.justify-end= group.lessons_max
  `
})

const GroupForm = ({id, onClose}) => {
  const params = useParams()

  const createGroup = useActGroups.useCreate()
  const updateGroup = useActGroups.useUpdate()
  const deleteGroup = useActGroups.useDelete()

  const group = useActGroups.useOne({id})

  const initialValues = {
    act_classroom_id: params.id,
    name: '',
    attend_max: 0,
    lessons_min: 0,
    lessons_max: 0,
    ...group,
  }

  const onSubmit = id == 'new' ? createGroup : updateGroup

  const onDelete = () =>
    confirm(`Deleting '${group.name}'. Are you sure?`) && deleteGroup({id})

  return pug`
    .col.gap-1
      FormProvider(initialValues=initialValues onSubmit=onSubmit onSuccess=onClose onCancel=onClose)
        .grid.grid-cols-5.items-center.gap-2
          TextField.col-span-2(id='group-name' focus=(id == 'new') name='name')
          NumberField.text-right(name='attend_max')
          NumberField.text-right(name='lessons_min')
          NumberField.text-right(name='lessons_max')

        .row.items-center.justify-between.gap-2
          if id != 'new'
            button.text-red-600.underline(onClick=onDelete) Delete
          .flex-1
          button.text-blue-600.underline(onClick=onClose) Cancel
          SubmitButton(variant='blue') Save
  `
}
