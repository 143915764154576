export const ActTeacherOptions = ({placeholder = 'All teachers'}) => {
  const teachers = useUsers()
    .filter((u) => u.act_classroom_id)
    .sort((a, b) => a.name.localeCompare(b.name))

  return pug`
    option(value='')= placeholder
    for teacher in teachers
      option(key=teacher.id value=teacher.id)= teacher.name
  `
}
