export const useItLogs = createQuery('/it_logs')

useItLogs.useCreate = createMutation('POST', '/it_logs', (ctx) => {
  useItLogs.prependOne(ctx)
})
useItLogs.useUpdate = createMutation('PATCH', '/it_logs/:id', (ctx) => {
  useItLogs.replaceOne(ctx)
})
useItLogs.useDelete = createMutation('DELETE', '/it_logs/:id', (ctx) => {
  useItLogs.removeOne(ctx)
})
