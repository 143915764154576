export const useItIssues = createQuery('/it_issues')

useItIssues.useCreate = createMutation('POST', '/it_issues', (ctx) => {
  useItIssues.prependOne(ctx)
})
useItIssues.useUpdate = createMutation('PATCH', '/it_issues/:id', (ctx) => {
  useItIssues.replaceOne(ctx)
})
useItIssues.useDelete = createMutation('DELETE', '/it_issues/:id', (ctx) => {
  useItIssues.removeOne(ctx)
})
