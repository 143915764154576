const route = {
  title: 'Logs',
  path: '/it/logs',
  scopes: ['it.manage'],
  menuGroup: 'Equipment',
}

export const ItLogPage = createPage(route, () => {
  return pug`
    Filters
    Main: List
  `
})

//
// Filters UI state
//
const useFiltersState = zus(() => ({
  editingId: null,

  it_location_id: '',
  it_kind_id: '',
  tag: '',
}))

const setFilters = useFiltersState.setState

const useFilters = () => {
  const filters = useFiltersState()

  const logsById = useItLogs.useById()
  const kindsById = useItKinds.useById()
  const locationsById = useItLocations.useById()

  const {dates, grouped: logsByLocationByDate} = useMemo(() => {
    if (isEmpty(logsById)) return {dates: [], grouped: {}}

    const datesLookup = {}
    const grouped = {}

    for (const log of logsById) {
      if (filters.it_location_id != '' && filters.it_location_id != log.it_location_id) continue
      if (filters.it_kind_id != '' && filters.it_kind_id != log.it_kind_id) continue
      if (filters.it_tag_id != '' && filters.it_tag_id != log.it_tag_id) continue

      const date = dayjs(log.date).format('YYYY-mm-dd')
      if (!datesLookup[date]) datesLookup[date] = true

      if (!grouped[date]) grouped[date] = {}
      if (!grouped[date][log.it_location_id]) grouped[date][log.it_location_id] = []
      grouped[date][log.it_location_id].push(log)
    }

    const dates = Object.keys(datesLookup).sort()
    return {dates, grouped}
  }, [logsById])

  return {...filters, logsByLocationByDate, kindsById, locationsById}
}

const Filters = () => {
  const hasAnyScopes = useHasAnyScopes()

  const filters = useFilters()

  return pug`
    Header.col.gap-1.sm_gap-2.shadow-md
      .flex-1.grid.grid-cols-6.gap-1.sm_gap-2
        Select.col-span-3.sm_col-span-2.h-10.flex-1(
          value=filters.it_location_id
          onChange=(e)=>setFilters({it_location_id: e.target.value})
        )
          ItLocationOptions(placeholder='All Locations')

        Select.col-span-3.sm_col-span-2.h-10.flex-1(
          value=filters.it_kind_id
          onChange=(e)=>setFilters({it_kind_id: e.target.value})
        )
          ItKindOptions(placeholder='All Kinds')

        Input.col-span-3.sm_col-span-2.h-10.flex-1(
          value=filters.tag
          onChange=(e)=>setFilters({tag: e.target.value})
          placeholder='Any Tag'
        )

        Button.col-span-6.sm_col-span-2.flex-1.w-full.py-1(
          onClick=()=>setFilters({editingId: 'new'})
        )
          Icon.h-6(icon='plus')
          | New log
  `
}

const List = () => {
  const filters = useFilters()

  return pug`
    if Object.keys(filters.logsByLocationByDate).length < 1
      .py-12.col.center
        Icon.h-20.mb-12.text-gray-400(icon='clipboard-check')
        .text-xl No logs recorded.
        .text-lg Try changing the filters above.

    else
      for day in Object.keys(filters.logsByLocationByDate)
        .col.gap-1(key=day)
          .text-2xl= dayjs(day).format('ddd MMM D')
          .pl-2
            for location_id in Object.keys(filters.logsByLocationByDate[day])
              .col.gap-1(key=location_id)
                .text-2xl= locationsById[location_id] && locationsById[location_id].name
                .pl-2
                  for log in filters.logsByLocationByDate[day][location_id]
                    pre= JSON.stringify(filters.logsByLocationByDate[day][location_id])
  `
}
