//
// Main navigation for the app.
// Should almost always be on top of the page.
//
export const NavHeader = ({title, path}) => {
  const me = useMe()
  if (!me.scopes.length) return null

  return pug`
    Header.row.items-center.gap-4
      Suspend(
        Failed=NavFailed
        Loading=NavLoading
      )
        NavReady(title=title path=path)
      .contain.font-black.text-gray-800= title
      .w-8
  `
}

const NavButton = ({className, icon, ...props}) => {
  return pug`
    button.flex-shrink-0.h-8.w-8.col.center(
      className=className
      ...props
    )
      Icon.h-6(icon=icon)
  `
}

const NavLoading = () => {
  return pug`
    NavButton.animate.animate-spin(icon='circle-notch')
  `
}

const NavFailed = () => {
  return pug`
    NavButton.text-red-600(icon='exclamation-triangle')
  `
}

const NavReady = ({title, path}) => {
  const params = useParams()

  const me = useMe()
  const hasAnyScopes = useHasAnyScopes()

  const [isOpen, setIsOpen] = React.useState(true)
  const className = isOpen ? 'translate-y-0' : '-translate-y-full'

  const visiblePageGroups = React.useMemo(() => {
    const groups = {}

    for (const [group, pages] of Object.entries(PAGE_GROUPS)) {
      if (group == 'null' || group == 'undefined') continue

      for (const page of pages) {
        if (!hasAnyScopes(...page.scopes)) continue

        if (!groups[group]) groups[group] = []
        groups[group].push(page)
      }
    }

    return groups
  }, [me.scopes])

  return pug`
    NavButton(icon='bars' onClick=()=>Overlay.open(NavFlyout, {me, visiblePageGroups}))
  `
}

const NavFlyout = ({me, visiblePageGroups}) => {
  const params = useParams()

  return pug`
    Flyout.col
      .flex-shrink-0.p-1.sm_p-2.row.items-center.gap-4.border-b.border-gray-400
        NavButton(icon='times' onClick=Overlay.close)
        .text-sm.font-mono.font-semibold= me.email

      .flex-1.col.p-4.gap-2.overflow-y-scroll
        for group in Object.keys(visiblePageGroups)
          .font-bold.border-b.border-gray-300(key=group)= group

          .pl-4.col.gap-2(key=group+'-list')
            for page in visiblePageGroups[group]
              Link(
                key=page.pathTemplate
                to=page.path(params)
                onClick=Overlay.close
              )
                .row.items-center.gap-2.text-blue-700.underline
                  = page.title
  `
}
