//
// Error icon, error info, and retry button.
//
// eslint-disable-next-line handle-callback-err
export const Failed = ({error, resetErrorBoundary, ...props}) => {
  if (error?.base instanceof Error) error = error.base
  if (error instanceof Error) {
    error = {error: error.message, stack: error.stack.split('\n')}
  }
  if (!error) error = {message: 'Something went wrong'}
  console.log({failed: error, json: JSON.stringify(error)})

  return pug`
    .h-full.w-full.col.center.gap-2
      Icon.h-20.text-red-500(icon='exclamation-triangle')

      Button(size='sm' variant='outline-gray' onClick=resetErrorBoundary)
        Icon.h-4(icon='redo')
        | Retry

      pre.w-full.max-w-screen-sm.p-2.whitespace-prewrap.bg-black.text-red-500.lg_rounded-lg.overflow-scroll
        = JSON.stringify(error, null, 2)
  `
}
