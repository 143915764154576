export const useItComments = createQuery('/it_issues/:it_issue_id/it_comments')

useItComments.useCreate = createMutation('POST', '/it_issues/:it_issue_id/it_comments', (ctx) => {
  useItComments.appendOne(ctx)
})
useItComments.useUpdate = createMutation('PATCH', '/it_comments/:id', (ctx) => {
  useItComments.replaceOne(ctx)
})
useItComments.useDelete = createMutation('DELETE', '/it_comments/:id', (ctx) => {
  useItComments.removeOne(ctx)
})
