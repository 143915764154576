//
// Groups a list of items by the result of a function.
// Returns an object with the function results as keys,
//   and values as a list of items that match.
//
// For example:
//   const records = [{type: 'x'}, {type: 'y'}, {type: 'x'}]
//   const recordsByType = groupBy(records, (r) => r.type)
//   const xRecords = recordsById['type']
//
export const groupBy = (xs, keyFn) =>
  (xs ?? []).reduce((o, x) => ({...o, [keyFn(x)]: [...(o[keyFn(x)] ?? []), x]}), {})
