export const useItKinds = createQuery('/it_kinds')

useItKinds.useCreate = createMutation('POST', '/it_kinds', (ctx) => {
  useItKinds.prependOne(ctx)
})
useItKinds.useUpdate = createMutation('PATCH', '/it_kinds/:id', (ctx) => {
  useItKinds.replaceOne(ctx)
})
useItKinds.useDelete = createMutation('DELETE', '/it_kinds/:id', (ctx) => {
  useItKinds.removeOne(ctx)
})
