import {library, config} from '@fortawesome/fontawesome-svg-core'

config.autoAddCss = false

import {faArrowLeft} from '@fortawesome/free-solid-svg-icons'
library.add(faArrowLeft)

import {faArrowRight} from '@fortawesome/free-solid-svg-icons'
library.add(faArrowRight)

import {faBars} from '@fortawesome/free-solid-svg-icons'
library.add(faBars)

import {faBuilding} from '@fortawesome/free-solid-svg-icons'
library.add(faBuilding)

import {faBuildingUser} from '@fortawesome/free-solid-svg-icons'
library.add(faBuildingUser)

import {faChalkboardTeacher} from '@fortawesome/free-solid-svg-icons'
library.add(faChalkboardTeacher)

import {faChartColumn} from '@fortawesome/free-solid-svg-icons'
library.add(faChartColumn)

import {faCheck} from '@fortawesome/free-solid-svg-icons'
library.add(faCheck)

import {faCheckCircle} from '@fortawesome/free-solid-svg-icons'
library.add(faCheckCircle)

import {faCircleNotch} from '@fortawesome/free-solid-svg-icons'
library.add(faCircleNotch)

import {faCog} from '@fortawesome/free-solid-svg-icons'
library.add(faCog)

import {faPencil} from '@fortawesome/free-solid-svg-icons'
library.add(faPencil)

import {faEnvelope} from '@fortawesome/free-solid-svg-icons'
library.add(faEnvelope)

import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
library.add(faExclamationTriangle)

import {faFolderOpen} from '@fortawesome/free-solid-svg-icons'
library.add(faFolderOpen)

import {faGears} from '@fortawesome/free-solid-svg-icons'
library.add(faGears)

import {faGlobe} from '@fortawesome/free-solid-svg-icons'
library.add(faGlobe)

import {faHome} from '@fortawesome/free-solid-svg-icons'
library.add(faHome)

import {faLifeRing} from '@fortawesome/free-solid-svg-icons'
library.add(faLifeRing)

import {faRedo} from '@fortawesome/free-solid-svg-icons'
library.add(faRedo)

import {faRepeat} from '@fortawesome/free-solid-svg-icons'
library.add(faRepeat)

import {faRobot} from '@fortawesome/free-solid-svg-icons'
library.add(faRobot)

import {faSignOut} from '@fortawesome/free-solid-svg-icons'
library.add(faSignOut)

import {faTableColumns} from '@fortawesome/free-solid-svg-icons'
library.add(faTableColumns)

import {faTags} from '@fortawesome/free-solid-svg-icons'
library.add(faTags)

import {faTimes} from '@fortawesome/free-solid-svg-icons'
library.add(faTimes)

import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'
library.add(faTimesCircle)

import {faUserCircle} from '@fortawesome/free-solid-svg-icons'
library.add(faUserCircle)

import {faWrench} from '@fortawesome/free-solid-svg-icons'
library.add(faWrench)

import {faHandshake} from '@fortawesome/free-solid-svg-icons'
library.add(faHandshake)

import {faPlus} from '@fortawesome/free-solid-svg-icons'
library.add(faPlus)

import {faMinus} from '@fortawesome/free-solid-svg-icons'
library.add(faMinus)

import {faReceipt} from '@fortawesome/free-solid-svg-icons'
library.add(faReceipt)

import {faMoneyBillTrendUp} from '@fortawesome/free-solid-svg-icons'
library.add(faMoneyBillTrendUp)

import {faIdCard} from '@fortawesome/free-solid-svg-icons'
library.add(faIdCard)

import {faUsers} from '@fortawesome/free-solid-svg-icons'
library.add(faUsers)

import {faSchool} from '@fortawesome/free-solid-svg-icons'
library.add(faSchool)

import {faSitemap} from '@fortawesome/free-solid-svg-icons'
library.add(faSitemap)

import {faCalendarCheck} from '@fortawesome/free-solid-svg-icons'
library.add(faCalendarCheck)

import {faGift} from '@fortawesome/free-solid-svg-icons'
library.add(faGift)

import {faList} from '@fortawesome/free-solid-svg-icons'
library.add(faList)

import {faBox} from '@fortawesome/free-solid-svg-icons'
library.add(faBox)

import {faBullseye} from '@fortawesome/free-solid-svg-icons'
library.add(faBullseye)

import {faStar} from '@fortawesome/free-solid-svg-icons'
library.add(faStar)

import {faClipboardCheck} from '@fortawesome/free-solid-svg-icons'
library.add(faClipboardCheck)

import {faArrowTrendUp} from '@fortawesome/free-solid-svg-icons'
library.add(faArrowTrendUp)

import {faArrowTrendDown} from '@fortawesome/free-solid-svg-icons'
library.add(faArrowTrendDown)

import {faMoneyCheckDollar} from '@fortawesome/free-solid-svg-icons'
library.add(faMoneyCheckDollar)

import {faLocationDot} from '@fortawesome/free-solid-svg-icons'
library.add(faLocationDot)

import {faTrash} from '@fortawesome/free-solid-svg-icons'
library.add(faTrash)

import {faNewspaper} from '@fortawesome/free-regular-svg-icons'
library.add(faNewspaper)

import {faFileText} from '@fortawesome/free-regular-svg-icons'
library.add(faFileText)

import {faRectangleList} from '@fortawesome/free-regular-svg-icons'
library.add(faRectangleList)

import {faFileCode} from '@fortawesome/free-regular-svg-icons'
library.add(faFileCode)

import {faSquarePlus} from '@fortawesome/free-regular-svg-icons'
library.add(faSquarePlus)

import {faFileArchive} from '@fortawesome/free-regular-svg-icons'
library.add(faFileArchive)

import {faGoogle} from '@fortawesome/free-brands-svg-icons'
library.add(faGoogle)
