//
// Returns a callback to test if the user is allowed to do something.
//
// If the user has any of the scopes given, they are allowed.
//
// NOTE: *any*, not *all*. This does an OR, not XOR / AND.
//
export const useHasAnyScopes = () => {
  const me = useMe()
  const scopesLookup = useGroupBy(me.scopes, (x) => x)

  return (...okScopes) => {
    if (okScopes.length < 1) return true

    for (let okScope of okScopes) {
      if (scopesLookup[okScope]) return true
    }

    return false
  }
}
