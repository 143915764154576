const route = {
  title: 'Accounts',
  path: '/ar/accounts',
  scopes: ['ar.manage'],
  menuGroup: 'Receivables',
}

export const ArAccountsPage = createPage(route, () => {
  const accounts = useArAccounts()

  const [isEditing, setIsEditing] = React.useState(false)

  return pug`
    Main
      .p-1.xs_p-2.col.gap-2
        if isEditing
          AccountForm(onClose=()=>setIsEditing(false))
        else
          Button(variant='outline-blue' onClick=()=>setIsEditing(true))
            Icon.h-4(icon='plus')
            | New Account

      .col.gap-2.p-1.xs_p-2
        for account in accounts
          Link(key=account.id to=ArAccountFormPage.path({id: account.id}))
            Account(id=account.id)
  `
})

const Account = ({id}) => {
  const account = useArAccounts.useOne({id})
  const parties = useArAccountParties({ar_account_id: id})

  return pug`
    .col.gap-1.p-1.xs_p-2.bg-gray-100.border.border-gray-500.rounded-lg
      .text-3xl.font-semibold= account.name
      .text-sm.truncate= account.description || 'No description'
      .text-sm.italic.text-gray-500 #{parties.length} part${parties.length == 1 ? 'y' : 'ies'}
  `
}

const AccountForm = ({onClose}) => {
  const navigate = useNavigate()

  const initialValues = {
    name: '',
    description: '',
  }

  const createAccount = useArAccounts.useCreate()

  const onSuccess = ({id}) => navigate(ArAccountFormPage.path({id}))

  return pug`
    FormProvider(
      initialValues=initialValues
      onSubmit=createAccount
      onSuccess=onSuccess
      onCancel=onClose
    )
      TextField.flex-1(
        focus id='account-name'
        name='name'
        placeholder='School Name'
      )

      .row.items-center.justify-between.gap-2
        button.text-blue-600.underline(onClick=onClose) Cancel
        .flex-1
        SubmitButton(variant='blue') Save
  `
}
