const route = {
  title: 'Classrooms',
  path: '/act/classrooms',
  scopes: ['act.manage'],
  menuGroup: 'Activity',
}

export const ActClassroomsPage = createPage(route, () => {
  const classrooms = useActClassrooms().sort((a, b) => a.name.localeCompare(b.name))

  const [isEditing, setIsEditing] = React.useState(false)

  return pug`
    Main
      .py-8.mb-8.px-1.xs_px-2.col.gap-2.border-b.border-gray-400
        if isEditing
          NewClassroomForm(onClose=()=>setIsEditing(false))
        else
          .center
            Button(variant='blue' onClick=()=>setIsEditing(true))
              Icon.h-4(icon='plus')
              | New Classroom

      .ml-10.grid.grid-cols-5.items-center.gap-2
        .col-span-2.pr-1 Classroom
        .row.justify-end.pr-2: Icon.h-4(icon='users')
        .row.justify-end.pr-2: Icon.h-4(icon='bullseye')
        .row.justify-end.pr-3: Icon.h-4(icon='star')

      .ml-10.grid.grid-cols-5.items-center.gap-2
        .col-span-2
        .row.justify-end.pr-2= classrooms.reduce((x, c) => x + c.attend_max, 0)
        .row.justify-end.pr-2= classrooms.reduce((x, c) => x + c.lessons_min, 0)
        .row.justify-end.pr-3= classrooms.reduce((x, c) => x + c.lessons_max, 0)

      .col.gap-2.p-1.xs_p-2
        for classroom, i in classrooms
          .row.items-center(key=classroom.id)
            .w-6.pr-2.text-right.font-black.opacity-20= i
            Link.flex-1.grid.grid-cols-5.py-1.px-2.text-lg.bg-white.border.border-gray-500.shadow.rounded(
              to=ActClassroomDetailsPage.path({id: classroom.id})
            )
              .col-span-2= classroom.name
              .row.justify-end= classroom.attend_max
              .row.justify-end= classroom.lessons_min
              .row.justify-end= classroom.lessons_max
  `
})

const NewClassroomForm = ({onClose}) => {
  const params = useParams()
  const navigate = useNavigate()

  const createClassroom = useActClassrooms.useCreate()

  const classroom = useActClassrooms.useOne({id: params.id})

  const onSuccess = ({id}) => navigate(ActClassroomDetailsPage.path({id}))

  return pug`
    FormProvider(initialValues=classroom onSubmit=createClassroom onSuccess=onSuccess onCancel=onClose)
      TextField.flex-1(id='classroom-name' focus name='name' placeholder='Name')

      .row.items-center.justify-between.gap-2
        button.text-blue-600.underline(onClick=onClose) Cancel
        SubmitButton(variant='blue') Save
  `
}
