//
// TODO: comments
//
export const InputField = ({name, label, className = 'py-2', ...props}) => {
  const {getField, setField, submitIfEnter, errors, isBusy} = useFormContext()

  return pug`
    div(className=className)
      if label !== false
        label.text-gray-600= label
      Input(
        value=getField(name) || ''
        onChange=(e)=>setField(name, e.target.value)
        invalid=errors[name]
        disabled=isBusy
        onKeyUp=submitIfEnter
        ...props
      )
      ErrorMessage(name=name)
  `
}

//
// TODO: comments
//
export const TextField = (props) => InputField({type: 'text', ...props})
export const NumberField = (props) => InputField({type: 'number', ...props})
export const DateField = (props) => InputField({type: 'date', ...props})
export const PasswordField = (props) => InputField({type: 'password', ...props})
