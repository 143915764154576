//
// Container for the main content on the page
//   i.e. the content below all headers & controls, which scrolls vertically.
//
// Wraps content in suspense: shows loading state & handles errors.
//
export const Main = ({className = '  ', children}) => {
  return pug`
    .contain.flex-1.overflow-y-scroll.pb-12(
      className=className
    )
      Suspend= children
  `
}
