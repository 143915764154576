const route = {
  title: 'Issues',
  path: '/it/issues',
  scopes: ['it.manage'],
  menuGroup: 'Tech Support',
}

export const ItIssuesPage = createPage(route, () => {
  const issues = useItIssues()

  const [isEditing, setIsEditing] = React.useState(false)

  return pug`
    Main
      .p-1.xs_p-2.col.gap-2
        if isEditing
          IssueForm(onClose=()=>setIsEditing(false))
        else
          Button(variant='outline-blue' onClick=()=>setIsEditing(true))
            Icon.h-4(icon='plus')
            | Report a problem

      .col.gap-2.p-1.xs_p-2
        for issue in issues
          Link(key=issue.id to=ItIssueFormPage.path({id: issue.id}))
            Issue(id=issue.id)
  `
})

const IssueContext = ({id}) => {
  const me = useMe()

  const issue = useItIssues.useOne({id})
  const user = useUsers.useOne({
    id: id == 'new' ? me.id : issue.user_id,
  })

  return pug`
    .row.items-end.gap-1
      Icon.h-6.text-gray-500(icon='user-circle')
      .text-xl.leading-none.font-bold.text-gray-600= user.name
  `
}

const Issue = ({id}) => {
  const issue = useItIssues.useOne({id})
  const comments = useItComments({it_issue_id: id})

  return pug`
    .col.gap-1.p-1.xs_p-2.bg-gray-100.border.border-gray-500.rounded-lg
      IssueContext(id=id)

      .text-3xl.font-semibold= issue.title
      .text-sm.truncate= issue.description || 'No description'

      .row.justify-between
        .text-sm.italic.text-gray-500 #{comments.length} comment${comments.length == 1 ? '' : 's'}
        .px-2.text-sm.italic.text-gray-500= dayjs(issue.created_at).fromNow()
  `
}

const IssueForm = ({onClose}) => {
  const me = useMe()
  const navigate = useNavigate()

  const initialValues = {
    title: '',
    user_id: me.id,
  }

  const createIssue = useItIssues.useCreate()

  const onSuccess = ({id}) => navigate(ItIssueFormPage.path({id}))

  return pug`
    FormProvider(
      initialValues=initialValues
      onSubmit=createIssue
      onSuccess=onSuccess
      onCancel=onClose
    )
      TextField.flex-1(
        focus id='issue-title'
        name='title'
        placeholder='Title'
      )

      .row.items-center.justify-between.gap-2
        button.text-blue-600.underline(onClick=onClose) Cancel
        .flex-1
        SubmitButton(variant='blue') Save
  `
}
