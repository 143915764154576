// Returns a path helper, given some parameterized path:
//
// '/records/:id' => ({id: 1}) => '/records/1'
//
// The path helper returns a fallback (the optional second argument) if any param is missing
//
// TODO: Add some docs for the builder, template, etc.
//
export const createPath = (template, defaultFallback = '#') => {
  const builder = (params = {}, fallback = defaultFallback) => {
    let paramsMissing = false

    const path = replaceParams(template, (paramName) => {
      const part = params[paramName]
      if (part !== '' && !part) paramsMissing = true
      return part
    })

    return paramsMissing ? fallback : path
  }

  builder.template = template

  builder.matcher = (params = {}) => {
    const pattern = replaceParams(template, (paramName) => params[paramName] || '[0-9a-zA-Z_-]+')
    const regexp = new RegExp(`^${pattern}`, 'u')
    return (path) => regexp.test(path)
  }

  builder.child = (childTemplate) => {
    const path = createPath(`${template}/${childTemplate}`)
    path.childTemplate = childTemplate
    return path
  }

  return builder
}

export const replaceParams = (template, replaceFn) =>
  template
    .split('/')
    .map((p) => (p.startsWith(':') ? replaceFn(p.slice(1)) : p))
    .join('/')
