const route = {
  title: 'Party Details',
  path: '/ar/parties/:id',
  scopes: ['ar.manage'],
  menuGroup: null,
}

export const ArPartyFormPage = createPage(route, () => {
  const navigate = useNavigate()

  const params = useParams()
  const party = useArParties.useOne({id: params.id})

  const updateParty = useArParties.useUpdate()
  const goBack = () => navigate(ArAccountFormPage.path({id: party.ar_account_id}))

  return pug`
    unless party.id
      Main
        .min-h-full.p-2.col.center
          Icon.h-20.mb-4.text-red-800.opacity-50(icon='handshake')
          p Looks like this party doesn't exist.

    else
      FormProvider(initialValues=party onSubmit=updateParty onSuccess=goBack onCancel=goBack)
        .contain.py-2.row.items-start.justify-between
          Link(to=ArAccountFormPage.path({id: party.ar_account_id}))
            .row.items-center.gap-2.text-lg.text-blue-800.underline
              Icon.h-4(icon='arrow-left')
              | Back to Account

          SubmitButton(variant='blue') Save

        Main
          FormSection(title='Internal' number='1')
            TextField(name='name' label='Name' placeholder='i.e. "Elijah Sawe"')
            TextAreaField(name='description' label='Description' placeholder='i.e. "Alumni & Board Member"')

          FormSection(title='Billing Address' number='2')
            TextField(name='meta.attn' label='Attn' placeholder='i.e. " Elijah Sawe (for Kaptien Primary)"')
            TextField(name='meta.addr1' label='Addr 1')
            TextField(name='meta.addr2' label='Addr 2')

          FormSection(title='Invoice Details' number='3')
            TextField(name='meta.prepaid_amt' label='Prepaid Amount')
            TextField(name='meta.prepaid_desc' label='Prepaid Description')
            TextField(name='meta.unpaid_amt' label='Unpaid Amount')
            TextField(name='meta.unpaid_desc' label='Unpaid Description')
            TextField(name='meta.item_amt' label='Item Amount')
            TextField(name='meta.item_desc' label='Item Description')
            TextField(name='meta.discout_amt' label='Discount Amount')
            TextField(name='meta.discout_desc' label='Discount Description')
  `
})

const ScopesField = () => {
  const {fields, updateFields} = useFormContext()

  const availableScopes = SCOPES.filter((s) => !fields.scopes?.includes(s))

  return pug`
    .grid.grid-cols-2.gap-2
      .font-semibold.text-gray-800 Allowed
      .font-semibold.text-gray-800 Denied

      .h-64.p-1.font-mono.bg-black.lg_rounded-lg.overflow-scroll
        for scope in fields.scopes
          button.block.text-green-500(
            key=scope
            onClick=()=>updateFields({scopes: fields.scopes.filter((s) => s != scope)})
          )= scope

      .h-64.p-1.font-mono.bg-black.lg_rounded-lg.overflow-scroll
        for scope in availableScopes
          button.block.text-green-500(
            key=scope
            onClick=()=>updateFields({scopes: [...fields.scopes, scope]})
          )= scope
  `
}

const Section = ({title, className = ' ', children}) => {
  return pug`
    .w-full.col.gap-1(className=className)
      .text-xl.font-bold.text-gray-600= title
      = children
  `
}
