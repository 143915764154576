export const useActClassrooms = createQuery('/act_classrooms')

useActClassrooms.useCreate = createMutation('POST', '/act_classrooms', (ctx) => {
  useActClassrooms.prependOne(ctx)
})
useActClassrooms.useUpdate = createMutation('PATCH', '/act_classrooms/:id', (ctx) => {
  useActClassrooms.replaceOne(ctx)
})
useActClassrooms.useDelete = createMutation('DELETE', '/act_classrooms/:id', (ctx) => {
  useActClassrooms.removeOne(ctx)
})
