import {createPortal} from 'react-dom'

const init = {
  status: 'closed',
  props: {},
  Comp: () => null,
}

export const useOverlay = zus(() => init)
const get = useOverlay.getState
const set = useOverlay.setState

export const Overlay = () => {
  const {Comp, props, status} = useOverlay()

  if (window === undefined) return null

  const overlay = pug`Comp(...props)`
  return createPortal(overlay, document.getElementById('overlay'))
}

Overlay.open = (Comp = init.Comp, props = init.props) => {
  set({status: 'opening', Comp, props})
  setTimeout(() => set({status: 'open', Comp, props}), 100)
}

Overlay.close = () => {
  set({status: 'closing'})
  setTimeout(() => set({...init, status: 'closed'}), 200)
}
