const route = {
  title: 'My Account',
  path: '/my/account',
  scopes: [],
  menuGroup: 'Account',
}

export const MyAccountPage = createPage(route, () => {
  const me = useMe()

  return pug`
    Main
      if me.scopes.length == 0
        .col.center.gap-y-12.py-12
          .text-6xl 🚀

          .text-center
            h2.text-lg.font-semibold Success! Please wait...
            p We need to confirm your account first.
            p If this is a mistake, try to reload the page.

          .row.center.flex-wrap.gap-2
            Button(variant='blue' onClick=()=>window.location.reload()) Reload the page
            Button(variant='red' onClick=signOut) Sign out

      else
        .col.center.gap-y-12.py-12
          .text-6xl 🚀

          .text-center
            h2.text-lg.font-semibold Success! You're in.
            p Here are your account details:

        pre.min-h-content.lg_mt-2.p-2.whitespace-prewrap.bg-black.text-green-400.lg_rounded-lg.overflow-scroll
          = JSON.stringify(me, null, 2)

        .col.center.gap-y-12.py-12
          Button(variant='red' onClick=signOut) Sign out
  `
})
