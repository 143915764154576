import logo from '../techlit-logo.svg'
import {useReactToPrint} from 'react-to-print'

const dateify = (d) => {
  const [_, month, day, year] = new Date(d).toDateString().split(' ')
  return `${month} ${day}, ${year}`
}

const commafy = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

const route = {
  title: 'Invoice Generator',
  path: '/ar/invoices',
  scopes: ['ar.manage'],
  menuGroup: 'Receivables',
}

export const ArInvoiceGeneratorPage = createPage(route, () => {
  const partiesById = useArParties.useById()

  const partyToState = (id) => {
    const party = partiesById[id]
    const meta = party?.meta ?? {}

    return {
      id,
      name: party?.name ?? '',
      ...(meta ?? {}),

      prepaidAmt: `${commafy(meta?.prepaid_amt ?? 0)} KES`,
      prepaidDesc: meta?.prepaid_desc ?? 'Balance',

      unpaidAmt: `${commafy(meta?.unpaid_amt ?? 0)} KES`,
      unpaidDesc: meta?.unpaid_desc ?? 'Balance',

      itemAmt: `${commafy(meta?.item_amt ?? 0)} KES`,
      itemDesc: meta?.item_desc ?? 'Installment 1 of 8',

      discountAmt: `${commafy(meta?.discount_mt ?? 0)} KES`,
      discountDesc: meta?.discount_desc ?? 'Discount',

      dueDesc: 'Due Feb 1',
    }
  }

  const id = nanoid()
    .replace(/-/g, 'U')
    .replace(/_/g, 'X')
    .replace(/I/g, 'W')
    .replace(/O/g, 'Y')
    .replace(/1/g, 'L')
    .replace(/0/g, 'Z')
    .slice(0, 8)
    .toUpperCase()

  const [state, setState] = React.useReducer((s1, s2) => ({...s1, ...s2}), {
    date: new Date(),

    fromAttn: 'Nelly Cheboi',
    fromAddress1: 'TechLit Kenya',
    fromAddress2: 'Zawadi Yetu',
    fromAddress3: 'Mogotio, Kenya',

    ...partyToState(Object.keys(partiesById)[0]),
  })
  React.useEffect(
    () => setState(partyToState(state.id || Object.keys(partiesById)[0])),
    [partiesById],
  )

  const totalAmt =
    parseInt(state.unpaidAmt.replace(/,/g, '')) -
    parseInt(state.prepaidAmt.replace(/,/g, '')) +
    parseInt(state.itemAmt.replace(/,/g, '')) -
    parseInt(state.discountAmt.replace(/,/g, ''))
  const total = `${commafy(totalAmt)} KES`

  const setParty = (e) => setState(partyToState(e.target.value))

  const showPrepaid = parseInt(state.prepaidAmt) > 0
  const showUnpaid = parseInt(state.unpaidAmt) > 0
  const showDiscount = parseInt(state.discountAmt) > 0

  const togglePrepaid = () => setState({prepaidAmt: showPrepaid ? '0 KES' : '1 KES'})
  const toggleUnpaid = () => setState({unpaidAmt: showUnpaid ? '0 KES' : '1 KES'})
  const toggleDiscount = () => setState({discountAmt: showDiscount ? '0 KES' : '1 KES'})

  const onClickDate = () => document.getElementById('date').showPicker()

  const bind = (field) => ({
    value: state[field] || '',
    onChange: (e) => setState({[field]: e.target.value}),
  })

  const slug = (state.name || 'party')
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/gi, '')
    .toLowerCase()

  const print = useReactToPrint({
    content: () => document.getElementById('invoice'),
    documentTitle: `${dayjs(state.date).format('YYYY-MM-DD')}-techlit-${slug}-invoice-${id}`,
  })

  if (!state.id || !state.name) return

  return pug`
    Header.row.justify-center.gap-2.shadow-md
      Select.max-w-96(onChange=setParty defaultValue=state.id)
        ArPartyOptions

      Button(size='sm' variant=(showPrepaid ? 'blue' : 'outline-blue') onClick=togglePrepaid) Prepaid
      Button(size='sm' variant=(showUnpaid ? 'red' : 'outline-red') onClick=toggleUnpaid) Unpaid
      Button(size='sm' variant=(showDiscount ? 'green' : 'outline-green') onClick=toggleDiscount) Discount

      Button(variant='blue' onClick=print) Print

      input#date.hidden(type='date' ...bind('date'))

    .w-full.flex-1.p-4.pt-24.col.center.bg-white.overflow-y-scroll
      #invoice.relative.p-14.col.align-start.justify-between.gap-10(
        style={width: '850px', height: '1100px'}
      )
        style(type='text/css' media='print' dangerouslySetInnerHTML={__html: '@page { size: portrait; }'})
        .grid.grid-cols-3.align-start
          img.h-16.mt-2(src=logo)
          .mt-2.text-center.text-xl.font-bold.text-gray-500.cursor-pointer(onClick=onClickDate)= dayjs(state.date).format('MMM D, YYYY')
          .text-right
            div.text-4xl.font-semibold.text-gray-700 Invoice
            div.text-lg.font-bold.text-gray-500 # #{id}

        .row.gap-4
          .flex-1.col.text-gray-800.text-xl
            .mb-4.font-bold.text-3xl.text-orange-900 Bill To
            input.inline.italic(...bind('attn'))
            input.font-bold.text-2xl.text-gray-700(...bind('name'))
            input(...bind('addr1'))
            input(...bind('addr2'))

          .col.text-gray-800.text-xl
            .mb-4.font-bold.text-3xl.text-orange-900 From
            input.inline.italic(...bind('fromAttn'))
            input.font-bold.text-2xl.text-gray-700(...bind('fromAddress1'))
            input(...bind('fromAddress2'))
            input(...bind('fromAddress3'))

        .col.text-lg.border.border-gray-300.rounded.overflow-hidden
          .grid.grid-cols-4.italic.font-bold.text-gray-600.bg-gray-100
            .col-span-3.p-2.text-left.border-r.border-gray-400 Description
            .p-2.text-right Amount

          if showPrepaid
            .grid.grid-cols-4.border-t.border-gray-600
              input.col-span-3.h-full.p-2.italic.font-medium.text-gray-700.bg-blue-100.border-r.border-gray-400(...bind('prepaidDesc'))
              input.h-full.p-2.italic.text-right.bg-blue-100(...bind('prepaidAmt'))

          if showUnpaid
            .grid.grid-cols-4.border-t.border-gray-600
              input.col-span-3.h-full.p-2.italic.font-medium.text-gray-700.bg-red-100.border-r.border-gray-400(...bind('unpaidDesc'))
              input.h-full.p-2.italic.text-right.bg-red-100(...bind('unpaidAmt'))

          .grid.grid-cols-4.items-end.border-t.border-gray-600
            .col-span-3.h-full.p-2.text-left.border-r.border-gray-400
              .leading-tight.font-medium.text-gray-700 Total ICT program - #[input.inline.italic(...bind('itemDesc'))]
              ul.list-disc.text-xs.pl-4
                li Up-to-date Computers
                li Safe & Secure Intranet
                li Evolving ICT Curriculum
                li Full-time TechLit Educator
            input.p-2.text-right(...bind('itemAmt'))

          if showDiscount
            .grid.grid-cols-4.border-t.border-gray-600
              input.col-span-3.h-full.p-2.italic.font-medium.text-gray-700.bg-green-600.bg-opacity-10.border-r.border-gray-400(...bind('discountDesc'))
              input.h-full.p-2.italic.text-right.bg-green-600.bg-opacity-10(...bind('discountAmt'))

          .grid.grid-cols-4.border-t-2.border-gray-800.text-gray-800.bg-gray-200
            .col-span-3.p-2.text-left.font-bold.border-r.border-gray-400 Total payment due
            .p-2.text-right.font-black.bg-gray-200= total

        .row
          .col
            .mb-4.font-bold.text-3xl.text-orange-900 Terms & Conditions
            ul.pl-6.list-disc.text-2xl.text-gray-700
              li PIA - Payment #[strong due in advance] of services
              li Paybill (no charge) #[strong 522522] a/c #[strong 6102789]

        .py-2.italic.text-2xl.font-medium.text-blue-900 Thank you for partnering with us. #[br] We are so excited to empower students with digital skills.

        input.absolute.top-20.right-72.w-64.h-24.font-bold.text-6xl.text-center.text-blue-800.bg-transparent.translate.-rotate-6.scale-125(
          style={fontFamily: 'Caveat'}
          ...bind('dueDesc')
        )
        input.absolute.bottom-64.right-12.w-64.h-24.font-bold.text-5xl.text-center.text-blue-800.bg-transparent.translate.rotate-12.scale-125(
          style={fontFamily: 'Caveat'}
          ...bind('dueDesc')
        )
  `
})
