const route = {
  title: 'My Paystubs',
  path: '/my/pay',
  scopes: ['me.earn', 'me.expense'],
  menuGroup: null,
}

export const MyPayPage = createPage(route, () => {
  return pug`
    Main
      .min-h-full.p-2.col.center
        Icon.h-20.mb-4.text-red-800.opacity-50(icon='times-circle')
  `
})
