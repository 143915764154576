//
// Container for headers on the page
//   i.e. the content on top that doesn't move.
//
// Wraps content in suspense: shows loading state & handles errors.
//
export const Header = ({className = ' ', children}) => {
  return pug`
    .flex-shrink-0.p-1.sm_p-2.border-b.border-gray-400(
      className=className
    )
      Suspend= children
  `
}
