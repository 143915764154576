export const Flyout = ({title, className = ' ', children, ...props}) => {
  const {status} = useOverlay()

  const zIndex = status == 'closed' ? '-1' : '21'
  const bgClx = status == 'open' ? ' opacity-100 ' : ' opacity-0 '
  const flyoutClx =
    status == 'open' ? ' opacity-100 translate-x-0 ' : ' opacity-0 -translate-x-full '

  return pug`
    .fixed.inset-0.bg-black.bg-opacity-40.backdrop-blur-md.transition.duration-200(
      className=bgClx
      style={zIndex}
      onClick=Overlay.close
      ...props
    )
      .fixed.top-0.left-0.w-full.max-w-64.bottom-0.bg-gray-100.border-r.border-gray-300.shadow-lg.transition.duration-200.overflow-hidden(
        className=(flyoutClx + className)
        onClick=(e)=>e.stopPropagation()
      )
        = children
  `
}
