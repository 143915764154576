//
// Adds a unique _key field if it doesn't exist,
//   recursing infinitely for arrays and n times for objects
//
export const addUniqueKeys = (obj, n = 2) => {
  if (n < 1) return obj

  if (Array.isArray(obj)) return obj.map((x) => addUniqueKeys(x, n))
  if (obj instanceof Object) {
    return Object.keys(obj).reduce(
      (o, k) => {
        // eslint-disable-next-line no-param-reassign
        o[k] = addUniqueKeys(obj[k], n - 1)
        return o
      },
      // eslint-disable-next-line no-underscore-dangle
      {_key: obj._key || nanoid()},
    )
  }
  return obj
}
