//
// TODO: comments
//
export const ErrorMessage = ({name, className, ...props}) => {
  const {errors} = useFormContext()
  return pug`
    if errors[name]
      .text-red-500(className=className ...props)= errors[name].join(', ')
  `
}
