export const useItLocations = createQuery('/it_locations')

useItLocations.useCreate = createMutation('POST', '/it_locations', (ctx) => {
  useItLocations.prependOne(ctx)
})
useItLocations.useUpdate = createMutation('PATCH', '/it_locations/:id', (ctx) => {
  useItLocations.replaceOne(ctx)
})
useItLocations.useDelete = createMutation('DELETE', '/it_locations/:id', (ctx) => {
  useItLocations.removeOne(ctx)
})
