import {Loading as DefaultLoading} from '~/comps/suspense/Loading.jsx'
import {Failed as DefaultFailed} from '~/comps/suspense/Failed.jsx'

//
// Container for volatile components.
//   1. Shows a loading state when components are busy (suspended).
//   2. Shows a failed state when they fail.
//
export const Suspend = ({Loading = DefaultLoading, Failed = DefaultFailed, children}) => {
  const loading = pug`Loading`

  return pug`
    ErrorBoundary(FallbackComponent=Failed)
      Suspense(fallback=loading)
        = children
  `
}
