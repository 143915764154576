const route = {
  title: 'Targets',
  path: '/act/targets',
  scopes: ['act.teach', 'act.observe', 'act.manage'],
  menuGroup: 'Activity',
}

export const ActTargetsPage = createPage(route, () => {
  const me = useMe()
  const classrooms = useActClassrooms().sort((a, b) => a.name.localeCompare(b.name))

  const [onlyTargets, setOnlyTargets] = React.useState(true)
  const [roomId, setRoomId] = React.useState(null)
  React.useEffect(() => {
    setRoomId(me.act_classroom_id || classrooms[0]?.id)
  }, [me.act_classroom_id, classrooms])

  const targets = useActTargets({act_classroom_id: roomId})

  const groupTargets = Object.values(targets)
    .filter(({name, all_lessons_max, bonus_lessons_max}) =>
      name && (onlyTargets ? bonus_lessons_max > 0 : all_lessons_max > 0))
    .sort((a, b) => a.name.localeCompare(b.name))

  const t = targets.total

  return pug`
    Main.col.gap-8.p-2
      .row.items-center.gap-2
        Select(value=roomId onChange=(e)=>setRoomId(e.target.value))
          for classroom in classrooms
            option(key=classroom.id value=classroom.id)= classroom.name

        Button(
          variant=(onlyTargets ? 'yellow' : 'blue')
          onClick=()=>setOnlyTargets(!onlyTargets)
        )
          Icon.h-4(icon=(onlyTargets ? 'bullseye' : 'calendar-check'))

      unless t
        Loading

      else
        .col
          .row.items-center.justify-around.text-4xl.text-gray-800.text-opacity-75
            .font-semibold #{onlyTargets ? t.bonus_lessons_sum : t.all_lessons_sum} / #{onlyTargets ? t.bonus_lessons_max : t.all_lessons_max}
            .hidden.sm_block.font-bold.opacity-50 lessons
            .font-black #{onlyTargets ? t.bonus_lessons_pct : t.all_lessons_pct}%

          .h-6.mx-4.flex-shrink-0.row.bg-white.border.rounded-full.overflow-hidden(
            className=(onlyTargets ? 'border-yellow-500' : 'border-blue-500')
          )
            .h-full(
              className=(onlyTargets ? 'bg-yellow-500' : 'bg-blue-500')
              style={width: (onlyTargets ? t.bonus_lessons_pct : t.all_lessons_pct) + '%'}
            )

          // .row.items-center.justify-around.text-lg.text-gray-800.text-opacity-50
          //   .font-semibold #{onlyTargets ? t.bonus_attend_sum : t.all_attend_sum} / #{onlyTargets ? t.bonus_attend_max : t.all_attend_max} #[small.pl-2.opacity-50 students]
          //   .font-black #{onlyTargets ? t.bonus_attend_pct : t.all_attend_pct}% #[small.pl-2.opacity-50 attendance]

        .grid.grid-cols-2.gap-4
          for t in groupTargets
            .col.p-1.sm_p-2.gap-2.bg-gray-100.border.border-gray-300.rounded-xl(key=t.id)
              .-mb-6.row.center.text-4xl.font-black.text-gray-800.text-opacity-50= t.name
              
              .row.items-center.justify-between.text-sm.text-gray-800.text-opacity-50
                .font-semibold #{onlyTargets ? t.bonus_lessons_sum : t.all_lessons_sum} / #{onlyTargets ? t.bonus_lessons_max : t.all_lessons_max}
                .font-black #{onlyTargets ? t.bonus_lessons_pct : t.all_lessons_pct}%

              .h-4.row.bg-white.border.rounded-full.overflow-hidden.opacity-50(
                className=(onlyTargets ? 'border-yellow-500' : 'border-blue-500')
              )
                .h-full(
                  className=(onlyTargets ? 'bg-yellow-500' : 'bg-blue-500')
                  style={width: (onlyTargets ? t.bonus_lessons_pct : t.all_lessons_pct) + '%'}
                )

              // .row.items-center.justify-around.text-xs.text-gray-800.text-opacity-25
              //   .font-semibold #{onlyTargets ? t.bonus_attend_sum : t.all_attend_sum} / #{onlyTargets ? t.bonus_attend_max : t.all_attend_max}
              //   .font-black #{onlyTargets ? t.bonus_attend_pct : t.all_attend_pct}%
  `
})
