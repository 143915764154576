const route = {
  title: 'Item Kinds',
  path: '/it/kinds',
  scopes: ['it.manage'],
  menuGroup: 'Equipment',
}

export const ItKindsPage = createPage(route, () => {
  const kinds = useItKinds()

  const [id, setId] = React.useState(null)

  return pug`
    Main
      .p-1.xs_p-2.col.gap-2
        if id == 'new'
          KindForm(id=id onClose=()=>setId(null))
        else
          Button(variant='outline-blue' onClick=()=>setId('new'))
            Icon.h-4(icon='plus')
            | New Kind

      .col.gap-2.p-1.xs_p-2
        for kind in kinds
          if id == kind.id
            KindForm(key=id id=id onClose=()=>setId(null))
          else
            button.col.py-1.px-2.text-lg.bg-white.border.border-gray-500.shadow.rounded(
              key=kind.id
              onClick=()=>setId(kind.id)
            )= kind.name
  `
})

const KindForm = ({id, onClose}) => {
  const createKind = useItKinds.useCreate()
  const updateKind = useItKinds.useUpdate()
  const deleteKind = useItKinds.useDelete()

  const kind = useItKinds.useOne({id})

  const onSubmit = (body) => (id == 'new' ? createKind(body) : updateKind(body))

  const onDelete = () => confirm(`Deleting '${kind.name}'. Are you sure?`) && deleteKind({id})

  return pug`
    FormProvider(initialValues=kind onSubmit=onSubmit onSuccess=onClose onCancel=onClose)
      TextField.flex-1(id='kind-name' focus name='name' placeholder='Name')

      .row.items-center.justify-between.gap-2
        button.text-blue-600.underline(onClick=onClose) Cancel
        .flex-1
        if id != 'new'
          button.text-red-600.underline(onClick=onDelete) Delete
        SubmitButton(variant='blue') Save
  `
}
