export const {Provider: FormProvider, useContext: useFormContext} = createContext(
  ({initialValues, onSubmit, onSuccess, onCancel}) => {
    const [fields, updateFields] = React.useReducer(
      (a, b) => ({...a, ...b}),
      addUniqueKeys(initialValues),
    )
    const [errors, updateErrors] = React.useReducer((a, b) => (b ? {...a, ...b} : {}), {})
    const [isBusy, setIsBusy] = React.useState(false)

    const getField = (path) => getIn(fields, path.split('.'))
    // eslint-disable-next-line no-shadow
    const setField = (path, value, {clearErrors = true} = {}) => {
      const match = hasNestedField(fields, ...path.split('.'))
      updateFields(setIn(fields, (match || path).split('.'), addUniqueKeys(value)))
      if (clearErrors) updateErrors({[match]: null})
    }
    const clearErrors = () => updateErrors(null)

    const wrapSubmit = (onSubmitFn) => async () => {
      setIsBusy(true)

      try {
        const rep = await onSubmitFn(fields)
        if (isEmpty(rep?.errors)) {
          onSuccess(rep.data)
        } else {
          const [matched, missing] = validationAdapter(fields, rep.errors)
          const base = Object.entries(missing).map(([name, message]) =>
            name === 'base' ? message : `${name}: ${message}`,
          )
          updateErrors({...matched, base})
        }
      } catch (e) {
        // if (import.meta.env.NODE_ENV === 'production') {
        //   Sentry.captureException(e)
        // } else {
        console.error(e)
        // }

        updateErrors(null)
      }

      setIsBusy(false)
    }

    const onSubmitWrapper = wrapSubmit(onSubmit)

    const submitIfEnter = (e) => {
      if (e.key != 'Enter') return
      e.preventDefault()
      onSubmitWrapper()
    }

    return {
      initialValues,
      fields,
      updateFields,
      getField,
      setField,
      errors,
      updateErrors,
      clearErrors,
      isBusy,
      setIsBusy,
      wrapSubmit,
      onSubmit: onSubmitWrapper,
      onSuccess,
      onCancel,
      submitIfEnter,
    }
  },
)
