const route = {
  title: 'Account Details',
  path: '/ar/accounts/:id',
  scopes: ['ar.manage'],
  menuGroup: null,
}

export const ArAccountFormPage = createPage(route, () => {
  const params = useParams()
  const account = useArAccounts.useOne({id: params.id})

  return pug`
    Main
      unless account
        .min-h-full.p-2.col.center
          Icon.h-20.mb-4.text-red-800.opacity-50(icon='exclamation-triangle')
          p Looks like this account doesn't exist.

      else
        AccountHeader
        AccountParties
  `
})

const AccountHeader = () => {
  const navigate = useNavigate()

  const params = useParams()
  const account = useArAccounts.useOne({id: params.id})

  const [isEditing, setIsEditing] = React.useState(false)
  const open = () => setIsEditing(true)
  const close = () => setIsEditing(false)

  const updateAccount = useArAccounts.useUpdate()
  const deleteAccount = useArAccounts.useDelete()

  const onDelete = async () => {
    if (!confirm(`Deleting '${account.name}'. Are you sure?`)) return

    await deleteAccount({id: issue.id})
    navigate(ArAccountsPage.path())
  }

  return pug`
    FormProvider(initialValues=account onSubmit=updateAccount onSuccess=close onCancel=close)
      .col.py-4.px-1.xs_px-2
        .row.items-start.gap-2
          // Go back link
          Link(to=ArAccountsPage.path())
            .mb-4.row.items-center.gap-2.text-lg.text-blue-800.underline
              Icon.h-4(icon='arrow-left')
              | All Accounts

          .flex-1
          if isEditing
            button.text-yellow-800.underline(onClick=close) Cancel
          else
            Button(variant='outline-blue' onClick=open) Edit

        // Form
        if isEditing
          TextField(name='name' label='Name')
          TextAreaField(
            name='description'
            label='Description'
            placeholder='i.e. "Public school in Nandi Hills"'
          )

          .mb-4.row.items-start.justify-between
            button.text-red-600.underline(onClick=onDelete) Delete
            SubmitButton(variant='blue') Save

        // Details
        else
          .py-4.text-4xl.font-semibold.text-gray-800= account.name
          .whitespace-pre-wrap.text-lg= account.description || 'No description'
  `
}

const AccountParties = () => {
  const params = useParams()
  const account = useArAccounts.useOne({id: params.id})

  const parties = useArAccountParties({ar_account_id: account.id})

  const [editingId, setEditingId] = React.useState(null)

  return pug`
    .col.gap-4.py-4.px-1.xs_px-2.border-t.border-gray-500
      for party in parties
        Link(key=party.id to=ArPartyFormPage.path({id: party.id}))
          Party(id=party.id)

      if editingId == 'new'
        PartyForm(id=editingId onClose=()=>setEditingId(null))
      else
        Button(variant='outline-blue' onClick=()=>setEditingId('new')) Add Party
  `
}

const Party = ({id}) => {
  const params = useParams()
  const party = useArAccountParties.useOne({ar_account_id: params.id, id})

  return pug`
    .col.p-1.xs_p-2.bg-gray-100.border.border-gray-500.rounded-lg
      .text-xl.font-semibold= party.name
      .text-sm.truncate= party.description || 'No description'
  `
}

const PartyForm = ({onClose}) => {
  const params = useParams()
  const navigate = useNavigate()

  const initialValues = {
    ar_account_id: params.id,
    name: '',
  }

  const createParty = useArAccountParties.useCreate()

  const onSuccess = ({id}) => navigate(ArPartyFormPage.path({id}))

  return pug`
    FormProvider(
      initialValues=initialValues
      onSubmit=createParty
      onSuccess=onSuccess
      onCancel=onClose
    )
      TextField.flex-1(
        focus id='party-name'
        name='name'
        placeholder='Name'
      )

      .row.items-center.justify-between.gap-2
        button.text-blue-600.underline(onClick=onClose) Cancel
        .flex-1
        SubmitButton(variant='blue') Save
  `
}
