const route = {
  title: 'Classroom Form',
  path: '/act/classrooms/:id/edit',
  scopes: ['act.manage'],
  menuGroup: null,
}

export const ActClassroomFormPage = createPage(route, () => {
  const naviate = useNavigate()
  const params = useParams()

  const updateClassroom = useActClassrooms.useUpdate()
  const deleteClassroom = useActClassrooms.useDelete()

  const classroom = useActClassrooms.useOne({id: params.id})
  if (!classroom.name) return pug`Main: Loading`

  const onClose = () => naviate(ActClassroomDetailsPage.path({id: params.id}))

  const onDelete = () =>
    confirm(`Deleting '${classroom.name}'. Are you sure?`) && deleteClassroom({id: params.id})

  return pug`
    Main
      FormProvider(initialValues=classroom onSubmit=updateClassroom onSuccess=onClose onCancel=onClose)
        .grid.grid-cols-2.gap-y-8.gap-x-2.p-1.xs_p-2
          button.row.items-center.gap-2.text-lg.text-blue-800.underline(onClick=onClose)
            Icon.h-4(icon='arrow-left')
            | Go Back

          .row.justify-end.gap-2
            SubmitButton(variant='blue') Save

          Section.col-span-2(title='Basic Info')
            TextField.flex-1(name='name' label='Name')

          Section.col-span-2.items-start(title='Danger Zone')
            Button(variant='outline-red' onClick=onDelete) Delete Classroom
  `
})

const Section = ({title, className = ' ', children}) => {
  return pug`
    .w-full.col.gap-1(className=className)
      .text-xl.font-bold.text-gray-600= title
      = children
  `
}
