import ReactDOM from 'react-dom/client'
import {HelmetProvider} from 'react-helmet-async'

// NOTE: We might want this, so I'm just keeping it around ~ Tyler
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()

// Global CSS setup
import '~/style.css'

// Libraries that need setup before anything else
import '~/init/dayjs.jsx'
import '~/init/fontawesome.jsx'

// Bootstrap application tree.
//   (Indented items are children of non-indented items)
//
// React.StrictMode warns about potential problems in development
// HelmetProvider is for managing head tags
// BrowserRouter sets up client-side routing
// Inside that, SwrProvider sets up remote data caching
// Inside that, Suspend shows a loading screen & catches errors
// Finally, Routes renders a page, depending on the browser location
const app = pug`
  React.StrictMode
    HelmetProvider
      BrowserRouter
        SwrProvider
          Suspend: Routes
          Overlay
`

// Render the app at #root in index.html
const root = document.getElementById('root')

// Start the app
ReactDOM.createRoot(root).render(app)
