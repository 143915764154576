const route = {
  title: 'Summary Generator',
  path: '/ar/summaries',
  scopes: ['ar.manage'],
  menuGroup: 'Receivables',
}

export const ArSummaryGeneratorPage = createPage(route, () => {
  return pug`
    Main
      .min-h-full.p-2.col.center
        Icon.h-20.mb-4.text-red-800.opacity-50(icon='times-circle')
  `
})
