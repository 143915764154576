// NOTE: Keep this in sync with User::SCOPES in app/models/user.rb
//
// NOTE: We could pull this over the API, but I don't want to expose it so easily.
//
const SCOPES = `
  me.earn me.expense
  act.observe act.teach act.manage
  it.manage users.manage ap.manage ap/expenses.manage ar.manage
`
  .split(/\s+/)
  .filter(Boolean)

const route = {
  title: 'Users',
  path: '/admin/users/:id',
  scopes: ['users.manage'],
  menuGroup: null,
}

export const AdminUserFormPage = createPage(route, () => {
  const navigate = useNavigate()

  const params = useParams()
  const user = useUsers.useOne({id: params.id})

  const updateUser = useUsers.useUpdate()
  const goBack = () => navigate(AdminUsersPage.path())

  return pug`
    Main
      unless user.id
        .min-h-full.p-2.col.center
          Icon.h-20.mb-4.text-red-800.opacity-50(icon='users')
          p Looks like this user doesn't exist.

      else
        FormProvider(initialValues=user onSubmit=updateUser onSuccess=goBack onCancel=goBack)
          .grid.grid-cols-2.gap-y-8.gap-x-2.p-1.xs_p-2
            Link(to=AdminUsersPage.path())
              .row.items-center.gap-2.text-lg.text-blue-800.underline
                Icon.h-4(icon='arrow-left')
                | All Users

            .row.justify-end
              SubmitButton(variant='blue') Save

            Section.col-span-2(title='Basic Info')
              TextField(name='name' label='Name')
              TextField(disabled name='email' label='Email')

            Section(title='Teacher')
              SelectField(name='act_classroom_id' label='Default Classroom')
                ActClassroomOptions(placeholder='-')

            Section(title='IT User')
              SelectField(name='it_location_id' label='Default Location')
                ItLocationOptions(placeholder='-')

            Section.col-span-2(title='Employee')
              .grid.grid-cols-2.gap-2
                TextField(name='payment_method' placeholder='-' label='Payment Method')
                TextField(name='payment_number' placeholder='-' label='Payment Number')

                TextField(name='retainer' placeholder='-' label='Retainer')
                TextField(name='commission' placeholder='-' label='Commission')

            Section.col-span-2(title='Scopes')
              ScopesField
  `
})

const ScopesField = () => {
  const {fields, updateFields} = useFormContext()

  const availableScopes = SCOPES.filter((s) => !fields.scopes?.includes(s))

  return pug`
    .grid.grid-cols-2.gap-2
      .font-semibold.text-gray-800 Allowed
      .font-semibold.text-gray-800 Denied

      .h-64.p-1.font-mono.bg-black.lg_rounded-lg.overflow-scroll
        for scope in fields.scopes
          button.block.text-green-500(
            key=scope
            onClick=()=>updateFields({scopes: fields.scopes.filter((s) => s != scope)})
          )= scope

      .h-64.p-1.font-mono.bg-black.lg_rounded-lg.overflow-scroll
        for scope in availableScopes
          button.block.text-green-500(
            key=scope
            onClick=()=>updateFields({scopes: [...fields.scopes, scope]})
          )= scope
  `
}

const Section = ({title, className = ' ', children}) => {
  return pug`
    .w-full.col.gap-1(className=className)
      .text-xl.font-bold.text-gray-600= title
      = children
  `
}
