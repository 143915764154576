export const useActLessonsByDate = createQuery('/act_lessons/by_date/:date')

useActLessonsByDate.useCreate = createMutation('POST', '/act_lessons', (ctx) => {
  useActTargets.mutate(ctx.input)
  useActLessonsByDate.appendOne(ctx)
})
useActLessonsByDate.useUpdate = createMutation('PATCH', '/act_lessons/:id', (ctx) => {
  useActTargets.mutate(ctx.input)
  useActLessonsByDate.replaceOne(ctx)
})
useActLessonsByDate.useDelete = createMutation('DELETE', '/act_lessons/:id', (ctx) => {
  useActTargets.mutate(ctx.input)
  useActLessonsByDate.removeOne(ctx)
})
