const route = {
  title: 'Grant Generator',
  path: '/ar/grants',
  scopes: ['ar.manage'],
  menuGroup: 'Receivables',
}

export const ArGrantGeneratorPage = createPage(route, () => {
  return pug`
    Main
      .min-h-full.p-2.col.center
        Icon.h-20.text-red-800.opacity-50(icon='times-circle')
  `
})
