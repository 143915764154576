const BASE_CLX =
  ' row center gap-3 border ' +
  ' rounded shadow-sm hover_shadow-md ' +
  ' outline-offset-0 focus_outline-2 smooth '

const SIZES = {
  sm: ' h-7  px-1 gap-2 text-base font-normal rounded    ',
  md: ' h-10 px-3 gap-3 text-lg   font-normal rounded-md ',
  lg: ' h-12 px-4 gap-4 text-xl   font-normal rounded-lg ',
}

const VARIANTS = {
  'blue': {
    base: ' text-white opacity-90 hover_opacity-100 ',
    enabled: ' bg-blue-500 border-blue-600 outline-blue-800 ',
    disabled: '  ',
    loading: '  ',
  },

  'gray': {
    base: ' text-white opacity-90 hover_opacity-100 ',
    enabled: ' bg-gray-500 border-gray-600 outline-gray-500 ',
    disabled: '  ',
    loading: '  ',
  },

  'yellow': {
    base: ' text-white opacity-90 hover_opacity-100 ',
    enabled: ' bg-yellow-500 border-yellow-600 outline-yellow-500 ',
    disabled: '  ',
    loading: '  ',
  },

  'green': {
    base: ' text-white opacity-90 hover_opacity-100 ',
    enabled: ' bg-green-500 border-green-600 outline-green-500 ',
    disabled: '  ',
    loading: '  ',
  },

  'red': {
    base: ' text-white opacity-90 hover_opacity-100 ',
    enabled: ' bg-red-500 border-red-600 outline-red-500 ',
    disabled: '  ',
    loading: '  ',
  },

  'outline-blue': {
    base: ' text-blue-600 ',
    enabled:
      ' hover_text-blue-700 border-blue-600 hover_border-blue-700 outline-blue-500 ',
    disabled: '  ',
    loading: '  ',
  },

  'outline-gray': {
    base: ' text-gray-500 ',
    enabled:
      ' hover_text-gray-600 border-gray-500 hover_border-gray-600 outline-gray-500 ',
    disabled: '  ',
    loading: '  ',
  },

  'outline-yellow': {
    base: ' text-yellow-600 ',
    enabled:
      ' hover_text-yellow-700 border-yellow-600 hover_border-yellow-700 outline-yellow-500 ',
    disabled: '  ',
    loading: '  ',
  },

  'outline-green': {
    base: ' text-green-600 ',
    enabled:
      ' hover_text-green-700 border-green-600 hover_border-green-700 outline-green-500 ',
    disabled: '  ',
    loading: '  ',
  },

  'outline-red': {
    base: ' text-red-600 ',
    enabled:
      ' hover_text-red-700 border-red-600 hover_border-red-700 outline-red-500 ',
    disabled: '  ',
    loading: '  ',
  },
}

export const Button = React.forwardRef(
  (
    {
      icon,
      disabled,
      size = 'md',
      variant = 'blue',
      className = '',
      children,
      ...props
    },
    ref,
  ) => {
    const v = VARIANTS[variant] || VARIANTS.blue
    const vclx = v.base + v[disabled ? 'disabled' : 'enabled']
    const sclx = SIZES[size] || SIZES.md
    className = `${BASE_CLX} ${vclx} ${sclx} ${className}`

    return pug`
      button(
        className=className
        disabled=disabled
        ref=ref
        ...props
      )
        if icon
          Icon.h-4(icon=icon)
        if icon && children
          span.pr-3
        = children
    `
  },
)
