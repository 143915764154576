import useSwr, {mutate as swrMutate} from 'swr'

//
// Creates a hook to load a collection over the api with the url path template given.
//
// For example:
//
//   // Creates a hook with many hooks and callbacks attached.
//   const useChapters = createQuery('/books/:book_id/chapters')
//

// 1. The use hook:
//
//   // Returns a collection of all the chapters in a book with id=xxx.
//   const chapters = useChapters({book_id: 'xxx'})
//
//   // The optional nullCollection argument is returned when the query returns nothing.
//   //   The default nullCollection is [].
//   const useChapters = createQuery('/books/:book_id/chapters', {nullCollection: [{title: 'xxx'}]})
//
//   // Now when there is no book with id=xxx, the nullCollection fallback is returned.
//   const chapters = useChapters({book_id: 'xxx'})
//

// 2. An lookup cache:
//
//   // Returns a lookup table of all the chapters in a book with id=xxx.
//   const chaptersById = useChapters.byId({book_id: 'xxx'})
//
//   // If a book with id=xxx has a chapter with id=xxx, then it is returned here.
//   const chapter = chaptersById['xxx']
//
// 3. A single record fetcher (with a nullRecord fallback):
//
//   // The fallback arg is optional. Default is an empty object.
//   const useChapters = createQuery('/books/:book_id/chapters', {nullRecord: {title: 'xxx'}})
//
//   // If a book with id=xxx has a chapter with id=xxx, then it is returned here.
//   const chapter = useChapters.one({book_id: 'xxx', id: 'xxx'})
//
//   // If not, then the null record is returned.
//   const missing = useChapters.one({book_id: 'xxx', id: 'xxx'})
//

// 4. Optimistic update callbacks:
//
//   // All these callbacks take a mutation "context" with an input arg and output response.
//   //   The input argument is passed to the use hook to instantiate the cache.
//   //   The output argument is used to change the cache.
//
//   // Invalidate the cache immediately, trigger suspense loading anywhere useChapters is called.
//   useChapters.reload({input, output})
//
//   // Replace a full collection in the cache.
//   useChapters.replace({input, output})
//
//   // Add one record to the beginning of the collection and add it to the index.
//   useChapters.prependOne({input, output})
//
//   // Add one record to the end of the collection and add it to the index.
//   useChapters.appendOne({input, output})
//
//   // Replace one record in the cache where output.id matches the id in the cache.
//   useChapters.replaceOne({input, output})
//
//   // Remove one record from the cache where output.id matches the id in the cache.
//   useChapters.removeOne({input, output})
//

export const createQuery = (pathTemplate, {nullCollection = [], nullRecord = {}} = {}) => {
  const path = createPath(pathTemplate, null)

  const useQuery = (input = {}, swrOptions = {}) => {
    const url = path(input)

    const apiFetch = useApiFetch()
    const fetcher = async () => {
      const rep = await apiFetch(url, {method: 'GET', body: input})
      if (!isEmpty(rep.errors)) throw rep
      return rep.data
    }

    const {data, error} = useSwr(url, fetcher, swrOptions)
    if (error?.errors) throw error.errors
    if (error) throw {data, errors: {base: error}, meta: {}}
    return data || nullCollection
  }

  useQuery.path = path

  //
  // Bonus Queries
  //
  useQuery.useById = (params = {}) => useIndexBy(useQuery(params), (x) => x.id)
  useQuery.useOne = (params = {}) => useQuery.useById(params)[params.id] || nullRecord

  //
  // Private Cache Methods: don't call this
  //
  const noop = (x) => x
  useQuery.mutate = (input = {}, mutator = noop, options = {revalidate: mutator == noop}) =>
    swrMutate(path(input), mutator, options)

  //
  // Public Cache Methods: call these instead
  //
  useQuery.reload = ({input}) => swrMutate(path(input), noop, {revalidate: true})

  useQuery.replace = ({input, output}) => useQuery.mutate(input, () => output)

  useQuery.prependOne = ({input, output}) => useQuery.mutate(input, (tx = []) => [output, ...tx])

  useQuery.appendOne = ({input, output}) => useQuery.mutate(input, (tx = []) => [...tx, output])

  useQuery.replaceOne = ({input, output}) =>
    useQuery.mutate(input, (tx = []) => tx.map((t) => (t.id == output.id ? output : t)))

  useQuery.removeOne = ({input, output}) =>
    useQuery.mutate(input, (tx = []) => tx.filter((t) => t.id != output.id))

  return useQuery
}
