//
// Creates a lookup table from a list of items using the result of a function.
// Returns an object with the function results as keys,
//   and one value (the last one found) that matches the key.
//
// For example:
//   const records = [{type: 'x'}, {type: 'y'}, {type: 'x'}]
//   const recordsByType = indexBy(records, (r) => r.type)
//   const lastX = recordsById['type']
//
export const indexBy = (xs, keyFn) => (xs ?? []).reduce((o, x) => ({...o, [keyFn(x)]: x}), {})
