//
// Creates a hook that prepares an API request callback.
//
// For example:
//
//   // Creates a callback that will:
//   //   1. Send a POST request to /users/:uid/money.
//   //   2. Replace the useMoney cache if successful.
//   const sendMoney = createMutation('POST', '/users/:uid/money', (ctx) => {
//     useMoney.replace(ctx)
//   })
//
//   // Make the request, update the cache if successful.
//   // Return the response (successful or not).
//   const response = sendMoney({uid: 'xxx'})
//

export const createMutation = (method, pathTemplate, mutateFn) => {
  const path = createPath(pathTemplate)

  return () => {
    const apiFetch = useApiFetch()

    return async (input) => {
      try {
        const url = path(input)
        const rep = await apiFetch(url, {method, body: input})
        const output = rep.data ?? {}

        if (isEmpty(rep.errors)) mutateFn({input, output})

        return rep
      } catch (e) {
        console.error(e)
        return {data: {}, errors: {base: e}, meta: {}}
      }
    }
  }
}
