export const useActGroups = createQuery('/act_groups/lookup')

useActGroups.useCreate = createMutation('POST', '/act_classrooms/:act_classroom_id/act_groups', ({input, output} = {}) => {
  useActClassrooms.mutate(
    useActClassrooms.path(input), 
    (clx) => clx.map(({act_group_ids = [], ...classroom}) =>
      classroom.id == output.act_classroom_id
        ? {...classroom, act_group_ids: [...act_group_ids, output.id]}
        : classroom
    ),
  )
  useActGroups.appendOne({input, output})
})

useActGroups.useUpdate = createMutation('PATCH', '/act_groups/:id', (ctx) => {
  useActGroups.replaceOne(ctx)
})

useActGroups.useDelete = createMutation('DELETE', '/act_groups/:id', ({input, output}) => {
  useActClassrooms.mutate(
    useActClassrooms.path(input),
    (clx) => clx.map(({act_group_ids = [], ...classroom}) =>
      classroom.id == output.act_classroom_id
        ? {...classroom, act_group_ids: act_group_ids.filter((id) => id != output.id)}
        : classroom
    ),
  )
  useActGroups.removeOne({input, output})
})
