const route = {
  title: 'Notes',
  path: '/act/lessons',
  scopes: ['act.observe', 'act.teach', 'act.manage'],
  menuGroup: 'Activity',
}

export const ActLessonsPage = createPage(route, () => {
  const hasAnyScopes = useHasAnyScopes()
  const filters = useFilters()
  const onNewLesson = () => Overlay.open(LessonFormModal, {})

  return pug`
    Header.col.gap-1.sm_gap-2.shadow-md
      .flex-1.grid.grid-cols-2.sm_grid-cols-4.gap-1.sm_gap-2
        Input.h-10.flex-1(
          value=filters.date
          onChange=(e)=>setFilters({date: e.target.value})
          type='date'
        )
        Select.h-10.flex-1(
          value=filters.classroom_id
          onChange=(e)=>setFilters({classroom_id: e.target.value})
        )
          ActClassroomOptions(placeholder='All Classrooms')

        Select.col-span-2.sm_col-span-1.h-10.flex-1(
          value=filters.teacher_id
          onChange=(e)=>setFilters({teacher_id: e.target.value})
        )
          ActTeacherOptions(placeholder='All Teachers')

        Button.col-span-2.sm_col-span-1.w-full.py-1(
          onClick=onNewLesson
        )
          Icon.h-6(icon='plus')
          span.font-semibold.truncate New Lesson

    Main.px-1.sm_px-2
      Suspend
        NotesList
  `
})

//
// useFilters Hook
//
const useFiltersState = zus(() => ({
  date: dayjs().format('YYYY-MM-DD'),
  classroom_id: '',
  teacher_id: '',
}))

const setFilters = useFiltersState.setState

const useFilters = () => {
  const filters = useFiltersState()
  const lessonsByRoom = useLessonsByRoom(filters.date)
  const me = useMe()

  const lessonsByRoomWithFilters = React.useMemo(() => {
    const withFilters = {}

    for (const room in lessonsByRoom) {
      if (filters.classroom_id != '' && filters.classroom_id != room) continue

      for (const lesson of lessonsByRoom[room]) {
        if (filters.teacher_id != '' && filters.teacher_id != lesson.user_id) continue

        if (!withFilters[room]) withFilters[room] = []
        withFilters[room].push(lesson)
      }
    }

    return withFilters
  }, [filters.classroom_id, filters.teacher_id, lessonsByRoom])

  return {...filters, user_id: me.id, lessonsByRoom: lessonsByRoomWithFilters}
}

//
// Organized lessons
//
const useLessonsByRoom = (date) => {
  const lessons = useActLessonsByDate({date})

  return React.useMemo(() => {
    if (!lessons) return {}

    return lessons.reduce((byRoom, lesson) => {
      if (!byRoom[lesson.act_classroom_id]) byRoom[lesson.act_classroom_id] = []
      byRoom[lesson.act_classroom_id].push(lesson)
      return byRoom
    }, {})
  }, [lessons])
}

//
// Notes List
//
const NotesList = () => {
  const classroomsById = useActClassrooms.useById()
  const teachersById = useUsers.useById()
  const filters = useFilters()

  return pug`
    if Object.keys(filters.lessonsByRoom).length < 1
      .py-12.col.center
        Icon.h-20.mb-12.text-gray-400(icon='chalkboard-teacher')
        h2 No lessons recorded.
        p Try changing the filters above.

    .py-4
      for room in Object.keys(filters.lessonsByRoom)
        Classroom(key=room room=room lessons=filters.lessonsByRoom[room])
  `
}

//
// Calculate total minutes in lesson
//
const lessonMinutes = (lesson) => {
  const today = dayjs().format('YYYY-MM-DD')
  const start = dayjs(`${today}T${lesson.start_time}`)
  const stop = dayjs(`${today}T${lesson.stop_time}`)
  return stop.diff(start) / 1000 / 60
}

//
// Display group of lessons by classroom
//
const Classroom = ({room, lessons}) => {
  const filters = useFilters()

  const classroom = useActClassrooms.useOne({id: room})

  const teachersById = useUsers.useById()

  const names = lessons.reduce((byName, lesson) => {
    const name = teachersById[lesson.user_id]?.name
    if (name) byName[name] = true
    return byName
  }, {})

  const heading = `${Object.keys(names).join(' & ')} @ ${classroom.name}`
  const students = lessons.reduce((x, lesson) => x + lesson.students, 0)
  const minutes = lessons.reduce((x, lesson) => x + lessonMinutes(lesson), 0)

  return pug`
    .text-2xl.font-semibold.text-gray-600.pb-2.pt-6.first_pt-0
      = heading

      .float-right.row.items-end.gap-1.sm_gap-2
        .px-1.text-base.font-bold.text-blue-800.bg-blue-200.rounded-md +#{minutes}m
        .px-1.text-base.font-bold.text-green-800.bg-green-200.rounded-md +#{students}s

    .mb-4.col.gap-1.sm_gap-2.clear-right
      for lesson, i in lessons
        Lesson(key=lesson.id lesson=lesson i=i)
  `
}

//
// Display one lesson
//
const Lesson = ({lesson}) => {
  const me = useMe()
  const hasAnyScopes = useHasAnyScopes()

  const filters = useFilters()

  const teacher = useUsers.useById({id: lesson.user_id})

  const groupsById = useActGroups.useById()

  const onClick = () => {
    if (lesson.user_id == me.id || hasAnyScopes('act.manage')) {
      Overlay.open(LessonFormModal, {lesson})
    } else {
      alert(`Can't edit lessons for ${teacher.name}.`)
    }
  }

  const start = lesson.start_time.split(':').slice(0, 2).join(':')
  const stop = lesson.stop_time.split(':').slice(0, 2).join(':')

  return pug`
    button.relative.pb-5.px-2.row.items-start.text-xl.text-left.text-gray-600.bg-gray-100.hover_bg-white.border.border-gray-400.rounded-lg.shadow-md.hover_shadow-lg.smooth(
      key=lesson.id
      onClick=onClick
    )
      .w-14.xs_w-24.sm_w-32.text-base.xs_text-lg.sm_text-xl.text-gray-500.font-bold.overflow-hidden.truncate
        if groupsById[lesson.act_group_id]
          = groupsById[lesson.act_group_id].name
        else
          = lesson.group

      .flex-1.text-sm.xs_text-base.sm_text-lg.text-left.whitespace-pre-wrap
        = lesson.activities

      .absolute.left-2.bottom-1
        .text-xs.xs_text-sm.sm_text-base.font-normal.leading-tight(
          style={paddingLeft: '8px', textIndent: '-8px'}
        ) #{start} - #{stop}

      .absolute.right-2.bottom-1.w-24.grid.grid-cols-2.gap-1.sm_gap-2
        .text-xs.px-1.font-bold.text-blue-500.bg-blue-100.rounded +#{lessonMinutes(lesson)}m
        .text-xs.px-1.font-bold.text-green-500.bg-green-100.rounded +#{lesson.students}s
  `
}

//
// Display a form for editing lessons in a modal
//
const LessonFormModal = ({lesson = {}}) => {
  const me = useMe()
  const filters = useFilters()
  const hasAnyScopes = useHasAnyScopes()

  const initialValues = {
    id: 'new',
    date: lesson.date || filters.date || dayjs().format('YYYY-MM-DD'),
    act_classroom_id: lesson.act_classroom_id || filters.classroom_id || '',
    user_id: lesson.user_id || me.id,
    start_time: '',
    stop_time: '',
    act_group_id: '',
    students: '',
    activities: '',
    ...lesson,
  }

  const createLesson = useActLessonsByDate.useCreate()
  const updateLesson = useActLessonsByDate.useUpdate()
  const deleteLesson = useActLessonsByDate.useDelete()

  const onSubmit = (form) => {
    const hasStart = form.start_time != ''
    const hasStop = form.stop_time != ''

    if (hasStart) {
      const startHour = dayjs(`${form.date} ${form.start_time}`).hour()
      if (startHour < 6 && !confirm(`${form.start_time} is very early. Continue?`)) return
      if (startHour > 20 && !confirm(`${form.start_time} is very late. Continue?`)) return
    }

    if (hasStop) {
      const stopHour = dayjs(`${form.date} ${form.stop_time}`).hour()
      if (stopHour < 6 && !confirm(`${form.stop_time} is very early. Continue?`)) return
      if (stopHour > 20 && !confirm(`${form.stop_time} is very late. Continue?`)) return
    }

    if (hasStart && hasStop) {
      const duration = lessonMinutes(form)
      if (duration > 50 && !confirm(`${duration} minutes is a very long lesson. Continue?`)) return
    }

    return lesson.id ? updateLesson(form) : createLesson(form)
  }

  const onDelete = () => {
    if (!confirm(`Delete '${lesson.name}'. Are you sure?`)) return

    deleteLesson({id: lesson.id})
    Overlay.close()
  }

  return pug`
    Modal.p-1.sm_p-2
      .pb-6.row.items-start.justify-between
        .text-2xl.font-semibold.text-gray-600.flex-1 #{lesson.id == 'new' ? 'New' : 'Edit'} Lesson
        Button.h-10.w-10(variant='red' onClick=Overlay.close)
          Icon.h-8(icon='times')

      FormProvider(
        initialValues=initialValues
        onSubmit=onSubmit
        onSuccess=Overlay.close
        onCancel=Overlay.close
      )
        .grid.grid-cols-2.items-start.gap-2
          ErrorMessage.grid-cols-2(name='base')

          if hasAnyScopes('act.manage')
            SelectField.col-span-2(name='user_id' label='Teacher')
              ActTeacherOptions

          SelectField(name='act_classroom_id' label='Classroom')
            ActClassroomOptions
          InputField(name='date' type='date' label='Date')
        
          .col-span-2.pt-2.w-full.border-b.border-gray-400

          InputField(name='start_time' type='time' label='Start')
          SelectField(name='act_group_id' label='Group'): GroupOptions

          InputField(name='stop_time' type='time' label='End')
          InputField(name='students' type='number' label='Students')

          TextAreaField.col-span-2(name='activities' rows=3 label='Activities')

          .col-span-2.pt-6.row.items-end
            if lesson.id
              button.text-red-600.underline(onClick=onDelete) Delete #[span.hidden.xs_inline lesson]
            .flex-1
            SubmitButton Save lesson
  `
}

const GroupOptions = () => {
  const {fields: {act_classroom_id}} = useFormContext()
  const classroom = useActClassrooms.useOne({id: act_classroom_id})
  console.log({ids: classroom.act_group_ids})

  const groupsById = useActGroups.useById()
  const groups = React.useMemo(() => {
    return (classroom.act_group_ids || [])
      .map((id) => groupsById[id])
      .filter(Boolean)
      .sort((a, b) => a.name.localeCompare(b.name))
  }, [classroom.act_group_ids, groupsById])

  return pug`
    option(value='') Select Group

    for group in groups
      option(key=group.id value=group.id)= group.name

    option(value='mixed') Mixed
  `
}
