//
// x = {a: 1, b_attributes: [{c: d}]}
//
// hasNestedField(x, ['a']) => 'a'
// hasNestedField(x, 'b.0.c'.split('.')) => 'b_attributes.0.c'
// hasNestedField(x, 'x.y.z'.split('.')) => undefined
//
export const hasNestedField = (obj, field, ...restFields) => {
  if (!obj) return

  const match = hasField(obj, field)
  if (!match) return
  if (restFields.length < 1) return match

  const restMatches = hasNestedField(obj[match], ...restFields)
  if (!restMatches) return
  return `${match}.${restMatches}`
}
