//
// TODO: comments
//
export const TextAreaField = ({name, label, className = 'py-2', ...props}) => {
  const {getField, setField, errors, isBusy} = useFormContext()

  return pug`
    div(className=className)
      if label !== false
        label= label
      TextArea(
        value=getField(name) || ''
        onChange=(e)=>setField(name, e.target.value)
        invalid=errors[name]
        disabled=isBusy
        ...props
      )
      ErrorMessage(name=name)
  `
}
