const route = {
  title: 'Vendor Invoices',
  path: '/ap/invoices',
  scopes: ['ap.manage'],
  menuGroup: 'Payables',
}

export const ApInvoicesPage = createPage(route, () => {
  return pug`
    Main
      .min-h-full.p-2.col.center
        Icon.h-20.mb-4.text-red-800.opacity-50(icon='times-circle')
  `
})
