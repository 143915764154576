const route = {
  title: 'Hardware Index',
  path: '/it/index',
  scopes: ['it.manage'],
  menuGroup: 'Equipment',
}

export const ItIndexPage = createPage(route, () => {
  return pug`
    Main
      .min-h-full.p-2.col.center
        Icon.h-20.mb-4.text-red-800.opacity-50(icon='times-circle')
  `
})
